import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class VariableDefs extends Component {
  createData(grade, index) {
    return { grade, index };
  }

  rows = [
    this.createData('A+', '145 or higher'),
    this.createData('A', '135 - 144'),
    this.createData('A-', '130 - 134'),
    this.createData('B+', '125 - 129'),
    this.createData('B', '115 - 124'),
    this.createData('B-', '110 - 114'),
    this.createData('C+', '105 - 109'),
    this.createData('C', '95 - 104'),
    this.createData('C-', '90 - 94'),
    this.createData('D+', '85 - 89'),
    this.createData('D', '75 - 84'),
    this.createData('D-', '70 - 74'),
    this.createData('F', '69 or lower'),
  ];

  render() {
    return (
      <div>
        <div >
          <div className="summaryTxt paddingB10">
            Current/Past National Indexes – Calculated by dividing a store’s annual sales by the chain’s annual sales (national, by store), multiplying times 100, and then averaging all of these indices obtained during the specific time period.
          </div>
          <div className="summaryTxt paddingB10">
            Current/Past Market Indexes – Calculated by dividing a store’s annual sales by the chain’s annual sales (in the same market, by store), multiplying times 100, and then averaging all of these indices obtained during the specific time period.
          </div>
          <div className="summaryTxt paddingB10">
            Current/Past National Grades / Current/Past Market Grades – Determined by first calculating the index of the store’s annual sales, and then assigning grades as detailed in the following table:
          </div>
          <div style={{maxWidth: '200px', alignSelf: 'center'}}>
            <TableContainer component={Paper}>
              <Table aria-label="hierarchy table" size="small">
                <TableBody>
                  {this.rows.map((row, i) => (
                    <TableRow key={'grade' + i}>
                      <TableCell component="th" scope="row" align="left">{row.grade}</TableCell>
                      <TableCell align="center">{row.index}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="summaryTxt paddingTB10">
            Current Years – The most recent five year time frame used for all variables labeled as ‘current’.
          </div>
          <div className="summaryTxt paddingB10">
            Past Years – Prior five year time frame used for all variables labeled as ‘past’.
          </div>
          <div className="summaryTxt paddingB10">
            Survey Year Last / Survey Year Next – Used to evaluate sales accuracy.  The most recent and second most recent survey years, within the current/past five year period in which Restaurant Trends obtained sales information.
          </div>
          <div className="summaryTxt paddingB10">
            Total number of surveys – Used to evaluate sales accuracy, it’s the number of sales data points collected during the five year time period.  The more surveys, the better the accuracy overall.  Exercise caution with only one survey on a store, since in most cases this is a relatively new store with limited sales history and seasonality.
          </div>
        </div>
      </div>
    );
  }
}