import React from 'react';
import '../../../App.css';
import axios from 'axios';
import { debounce } from 'lodash';

const titleH2 = {
  margin: '-15px -20px',
  padding: '15px 20px 15px 20px',
  fontSize: '1.2em',
  lineHeight: '1.1',
  letterSpacing: '-.02em'
}

const iconSpan = {
  paddingRight: '10px'
}

const textAreaDiv = {
  paddingTop: '15px',
  marginBottom: '14px'
}

const errorMessage = {
  color: '#ed1b34',
  paddingTop: '5px'
}

const loaderOuter = {
  overflow: 'hidden'
}

const loadingText = {
  margin: '0 auto',
  padding: '20px 10px 10px 10px',
  textAlign: 'center'
}

class SetRadius extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorMessage: false,
      errorMessageText: 'Please enter minutes greater than 0.',
      drivetimeLoading: false
    }

    this.toggleRingType = this.toggleRingType.bind(this);
    this.onChangeDebounced = debounce(this.onChangeDebounced, 1500);
    this.setFilters = this.setFilters.bind(this);
  }

  componentDidMount() {
  }

  setRadius(e) {
    this.props.setRadius(e.target.value);
  }

  setDriveTime(e) {
    this.props.setRadius(e.target.value);
    let inputValue = e.target.value;

    //disable filter button
    document.getElementById("setRadiusFilterBtn").disabled = true;

    if (inputValue > 0) {
      this.onChangeDebounced(inputValue);
    }
  }

  onChangeDebounced = (inputValue) => {
    const latLng = 'lat=' + this.props.points[0].position.lat + '&lng=' + this.props.points[0].position.lng;
    const rings = '&rings=[' + inputValue + ']&atlasmode=DriveTimeBufferMinutes';
    let token = localStorage.getItem('access_token');
    const headers = {
      'Authorization': 'Bearer ' + token
    };
    this.setState({
      drivetimeLoading: true
    });

    //save the new request for cancellation
    this._source = axios.CancelToken.source();

    //cancel previous request
    if (typeof this._source != typeof undefined) {
      this._source.cancel('Operation canceled due to new request.');
    }

    axios.get(process.env.REACT_APP_APIURL + '/drawStudyAreas?' + latLng + rings, { headers: headers }, { cancelToken: this._source.token })
      .then((result) => {
        if (result) {
          if (this.props.driveTime) {
            const ringsArray = result.data[0].featureset.features[0].geometry.rings[0];

            let newPolyArray = [];

            const reverseArray = ringsArray.map(innerArray => innerArray.reverse());
            newPolyArray.push({ id: 'drivetime', position: reverseArray });

            this.props.updatePolyArray(newPolyArray);
          }

          this.setState({
            showErrorMessage: false,
            drivetimeLoading: false
          });

          //enable filter button
          document.getElementById("setRadiusFilterBtn").disabled = false;
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error);
        } else {
          console.log(error);
        }

        this.setState({
          showErrorMessage: true,
          drivetimeLoading: false
        });

        //disable filter button
        document.getElementById("setRadiusFilterBtn").disabled = true;
      });
  }

  toggleRingType(e) {
    if (this.props.driveTime === false) {
      this.props.updateDriveTime(true);
      this.props.setRadius(0);
    } else {
      this.props.updateDriveTime(false);
      this.props.updatePolyArray([]);
      if (this.props.radiusVal === 0) {
        this.props.setRadius(1);
      }

      //enable filter button
      document.getElementById("setRadiusFilterBtn").disabled = false;
    }
  }

  setFilters() {
    if (this.props.radiusVal > 0) {
      this.props.showFilters();
    } else {
      this.setState({
        showErrorMessage: true
      });
    }
  }

  componentWillUnmount() {
  }

  render() {
    let ringTypeName = 'ring';
    if (this.props.driveTime === false) {
      ringTypeName = 'route';
    }

    return (
      <div>
        <h2 style={titleH2}>
          <span style={iconSpan}>
            <svg id="ringsIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4383" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.14,56A46,46,0,1,1,41,44" transform="translate(37.11 37.3)" />
              <path id="Path_4383-2" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.72,42a31.58,31.58,0,1,0-21.21-8.18" transform="translate(37.11 37.3)" />
              <path id="Path_4384" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.76-7.31A17.63,17.63,0,1,0,21.6-2.74" transform="translate(37.11 37.3)" />
            </svg>
          </span>
          Select Ring or Route</h2>
        <div style={textAreaDiv}>
          {this.state.drivetimeLoading ?
            <div style={loaderOuter}>
              <div className="filter-loader"></div>

              <div style={loadingText}>Loading drivetime...</div>

            </div>
            :
            <div>
              <div style={{ display: 'flex' }}>
                {this.props.driveTime === false ?
                  <div>
                    <input
                      id="radius-input"
                      min="1"
                      className="radiusInput"
                      value={this.props.radiusVal}
                      type="number"
                      onChange={(e) => { this.setRadius(e) }}
                    /><span style={{ paddingLeft: '5px' }}>Miles</span>
                  </div>
                  :
                  <div>
                    <input
                      id="drivetime-input"
                      min="0"
                      className="radiusInput noSpinner"
                      value={this.props.radiusVal}
                      type="number"
                      onChange={(e) => { this.setDriveTime(e) }}
                    /><span style={{ paddingLeft: '5px' }}>Minutes</span>
                  </div>
                }
                <div style={{ marginLeft: '20px', paddingTop: '4px' }}>
                  <button className="geocodeBtnSmall grayBkgd" onClick={this.toggleRingType}>Change to {ringTypeName}</button>
                </div>
              </div>
              <div className="geocodeDiv flex">
                <button className="geocodeBtn" onClick={this.props.backToStart}>Back</button>
                <button id="setRadiusFilterBtn" className="geocodeBtn" onClick={this.setFilters}>Set Filters</button>
              </div>
              {this.state.showErrorMessage ?
                <div style={errorMessage}>
                  {this.state.errorMessageText}
                </div>
                : null}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default SetRadius;