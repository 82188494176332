import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class FAQComponent extends Component {
  render() {
    const id = 'panel4' + this.props.id + '-header' || "id";
    const aria = 'panel4' + this.props.id + '-content' || "content";
    const question = this.props.question || "question";
    const answer = this.props.answer || "answer";

    return (
      <div className="method-grid-item">
        <Accordion className="innerExpansionPanel" square>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={aria} id={id}>
            <span className="blueTitle">Q:</span><span className="faqQ">{question}</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faqAText">
              <span className="blueTitle faqApadding">A:</span>
              {this.props.id === 'h' ?
                <span className="faqA">Colliers subscribes to Restaurant Trends data, and we do not have authority to modify that data.  If you feel there is incorrect information regarding a particular location(s), please email <a href="mailto:colliers.gis@colliers.com">Colliers GIS</a> with the pertinent details and we will pass on your question and supporting information to Restaurant Trends.  Discrepancies can happen for a variety of, and often valid, reasons.  To the extent there are errors in the data or changes based on your feedback, they will be amended and sent in the subsequent data update which occurs biannually around April and September each year.</span>
                :
                <span className="faqA">{answer}</span>
              }
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}