import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class CustomMapBtns extends React.Component {
  constructor(props) {
    super(props);

    this.toggleBasemap = this.toggleBasemap.bind(this);
  }

  toggleBasemap() {
    if (this.props.basemap === 'colliers-grey') {
      this.props.updateBasemap('hybrid');
    } else {
      this.props.updateBasemap('colliers-grey');
    }
  }

  render() {
    const basemapBtnImageClass = this.props.basemap === 'hybrid' ? 'mapCustomBtnImageS' : 'mapCustomBtnImageH'
    return (
      <div>
        <div className="mapSideButtonsDiv">
          <Tooltip title="Toggle basemap" placement="left">
            <div className={basemapBtnImageClass} onClick={this.toggleBasemap}></div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default CustomMapBtns;