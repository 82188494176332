import React from 'react';
import '../../../App.css';
import Filters from '../Filters/Filters';
import TypeAddress from '../TypeAddress/TypeAddress';
import ClickMap from '../ClickMap/ClickMap';
import StndGeography from '../StndGeograpy/StndGeography';
import Draw from '../Draw/Draw';
import SetRadius from '../SetRadius/SetRadius';
import axios from 'axios';
import FileSaver from 'file-saver';
import { polygon, transformScale, point, distance, destination } from '@turf/turf';

const apiToken = localStorage.getItem('access_token');

const headers = {
  'Authorization': 'Bearer ' + apiToken
};

class GetReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportMethodType: 'none',
      showRadiusSection: false,
      showFilters: false,
      geogQuery: '',
      prevMapRestPt: this.props.mapRestPoint,
      reportName: 'Custom Report',
      sidePanelClass: 'sidePanelStyle',
      showHideFilterClass: false,
      printing: false,
      printError: false,
      reportAddress: ''
    }

    this.closePanel = this.closePanel.bind(this);
    this.backToStart = this.backToStart.bind(this);
    this.showFilters = this.showFilters.bind(this);
    this.hideFilters = this.hideFilters.bind(this);
    this.showRadiusSection = this.showRadiusSection.bind(this);
    this.setMethodType = this.setMethodType.bind(this);
    this.updateGeogQuery = this.updateGeogQuery.bind(this);
    this.setRadius = this.setRadius.bind(this);
    this.updateReportName = this.updateReportName.bind(this);
    this.printMap = this.printMap.bind(this);
    this.updateHideFilterClass = this.updateHideFilterClass.bind(this);
    this.updatePrintError = this.updatePrintError.bind(this);
    this.updateReportAddress = this.updateReportAddress.bind(this);
  }

  componentDidMount() {
    let updateObj = {
      reportMethodType: this.getMethodType()
    }

    if (this.props.mapRestPoint) {
      this.props.updateExplore(false);
      const name = this.props.points[0].content.id || 'Custom Report';
      const address = this.props.points[0].content.id || '';
      updateObj.showRadiusSection = true;
      updateObj.reportName = name;
      updateObj.reportAddress = address;
    }
    this.setState(updateObj);
  }

  componentDidUpdate() {
    if (this.state.prevMapRestPt !== this.props.mapRestPoint) {
      if (this.props.mapRestPoint === true) {
        const name = this.props.points[0].content.id || 'Custom Report';
        const address = this.props.points[0].content.id || '';
        this.setState({
          prevMapRestPt: this.props.mapRestPoint,
          reportMethodType: this.getMethodType(),
          showRadiusSection: true,
          showFilters: false,
          reportName: name,
          reportAddress: address
        });
        this.props.updatePolyArray([]);
        this.props.updateEditControlLayer({});
      } else {
        this.setState({
          prevMapRestPt: this.props.mapRestPoint,
          reportMethodType: this.getMethodType(),
        });
      }
    }
  }

  getMethodType() {
    if (this.props.mapRestPoint === true) {
      return 'restPoint';
    } else if (this.props.searchAddressShow === true) {
      return 'typeAddress';
    } else if (this.props.clickShow === true) {
      return 'clickMap';
    } else if (this.props.geogShow === true) {
      return 'stndGeo';
    } else if (this.props.drawShow === true) {
      return 'draw';
    } else if (this.props.printShow === true) {
      return 'print';
    } else {
      return 'none'
    }
  }

  setMethodType(type) {
    this.setState({
      reportMethodType: type
    });
  }

  closePanel() {
    this.props.togglePanel('searchAddress');
  }

  setRadius(value) {
    this.props.updateRadiusVal(value);
  }

  backToStart() {
    if (this.getMethodType() === 'restPoint') {
      this.props.togglePanel();
    } else {
      this.setState({
        showRadiusSection: false,
        reportMethodType: this.getMethodType()
      });
    }
  }

  showRadiusSection() {
    this.setState({
      showRadiusSection: true,
    });
  }

  showFilters() {
    this.setState({
      showFilters: true,
      showRadiusSection: false
    });
  }

  hideFilters() {
    const methodType = this.getMethodType();
    if (methodType === 'typeAddress' || methodType === 'clickMap' || methodType === 'restPoint') {
      this.setState({
        showFilters: false,
        showRadiusSection: true
      });
    } else if (methodType === 'print') {
      this.props.togglePanel();
    } else {
      this.setState({
        showFilters: false,
        reportMethodType: this.getMethodType()
      });
    }
  }

  updateGeogQuery(value) {
    this.setState({
      geogQuery: value
    });
  }

  updateReportName(name) {
    this.setState({
      reportName: name
    });
  }

  updateHideFilterClass(bool) {
    this.setState({
      showHideFilterClass: bool
    });
  }

  updatePrintError() {
    this.setState({
      printError: false
    });
  }

  updateReportAddress(address) {
    this.setState({
      reportAddress: address
    });
  }

  printMap(queryObj) {
    this.setState({
      printing: true
    });
    const north = this.props.mapBounds._northEast.lat;
    const south = this.props.mapBounds._southWest.lat;
    const east = this.props.mapBounds._northEast.lng;
    //const west = this.props.mapBounds._southWest.lng;
    const center = this.props.mapPrintCenter;

    const dist = distance(point([east, north]), point([east, south]), { units: 'miles' });

    const newEastPt = destination(point([center[1], center[0]]), dist / 2, 90, { units: 'miles' });
    const newWestPt = destination(point([center[1], center[0]]), dist / 2, 270, { units: 'miles' });
    const newEastLng = newEastPt.geometry.coordinates[0];
    const newWestLng = newWestPt.geometry.coordinates[0];

    const origBbox = polygon([[[newEastLng, north], [newEastLng, south], [newWestLng, south], [newWestLng, north], [newEastLng, north]]]);
    const scaledBbox = transformScale(origBbox, 1.58);
    const newEast = scaledBbox.geometry.coordinates[0][1][0];
    const newWest = scaledBbox.geometry.coordinates[0][2][0];

    queryObj.north = north;
    queryObj.south = south;
    queryObj.west = newWest;
    queryObj.east = newEast;
    queryObj.name = this.state.reportName;

    axios.post(process.env.REACT_APP_APIURL + '/map', queryObj, { headers: headers, responseType: 'blob' })
      .then((result) => {
        if (result && result.statusText === "OK") {
          FileSaver.saveAs(result.data, 'restaurant_bytes_map.pdf');
        }
        this.setState({
          printError: false,
          printing: false
        });
      }).catch(error => {
        this.setState({
          printError: true,
          printing: false
        });
      });
  }

  componentWillUnmount() {
    this.props.updatePointsArray([]);
    this.props.updatePolyArray([]);
  }

  render() {
    var styleClass = this.state.showHideFilterClass ? 'hideFilterStyle' : 'sidePanelStyle';
    return (
      <div className={styleClass}>
        {this.state.reportMethodType === 'typeAddress' ?
          <TypeAddress
            updatePointsArray={this.props.updatePointsArray}
            updateMapCenter={this.props.updateMapCenter}
            updateMapZoom={this.props.updateMapZoom}
            showRadiusSection={this.showRadiusSection}
            setMethodType={this.setMethodType}
            updateReportName={this.updateReportName}
            updateReportAddress={this.updateReportAddress}
          />
          : null}
        {this.state.reportMethodType === 'clickMap' ?
          <ClickMap
            updateMapAddPt={this.props.updateMapAddPt}
            setMethodType={this.setMethodType}
            showRadiusSection={this.showRadiusSection}
            points={this.props.points}
            updateReportName={this.updateReportName}
            updateReportAddress={this.updateReportAddress}
          />
          : null}
        {this.state.reportMethodType === 'stndGeo' ?
          <StndGeography
            setMethodType={this.setMethodType}
            showFilters={this.showFilters}
            updateGeogQuery={this.updateGeogQuery}
            updatePolyArray={this.props.updatePolyArray}
            polys={this.props.polys}
            updateReportName={this.updateReportName}
            updateReportAddress={this.updateReportAddress}
          />
          : null}
        {this.state.reportMethodType === 'draw' ?
          <Draw
            setMethodType={this.setMethodType}
            showFilters={this.showFilters}
            updateMapDrawPt={this.props.updateMapDrawPt}
            polys={this.props.polys}
            updateReportName={this.updateReportName}
          />
          : null}
        {this.state.showRadiusSection ?
          <SetRadius
            showFilters={this.showFilters}
            backToStart={this.backToStart}
            updateMapRestPoint={this.props.updateMapRestPoint}
            mapRestPoint={this.state.prevMapRestPoint}
            setMethodType={this.setMethodType}
            setRadius={this.setRadius}
            radiusVal={this.props.radiusVal}
            driveTime={this.props.driveTime}
            updateDriveTime={this.props.updateDriveTime}
            points={this.props.points}
            updatePolyArray={this.props.updatePolyArray}
          ></SetRadius>
          : null}
        {this.state.showFilters || this.state.reportMethodType === 'print' ?
          <Filters
            hideFilters={this.hideFilters}
            points={this.props.points}
            polys={this.props.polys}
            radiusVal={this.props.radiusVal}
            methodType={this.getMethodType()}
            geogQuery={this.state.geogQuery}
            updateFilterVal={this.props.updateFilterVal}
            minSalesFilterVal={this.props.minSalesFilterVal}
            maxSalesFilterVal={this.props.maxSalesFilterVal}
            reportName={this.state.reportName}
            updateReportName={this.updateReportName}
            driveTime={this.props.driveTime}
            segmentFilterVal={this.props.segmentFilterVal}
            subsegmentFilterVal={this.props.subsegmentFilterVal}
            categoryFilterVal={this.props.categoryFilterVal}
            chainFilterVal={this.props.chainFilterVal}
            printMap={this.printMap}
            mapBounds={this.props.mapBounds}
            mapMoved={this.props.mapMoved}
            updateHideFilterClass={this.updateHideFilterClass}
            printing={this.state.printing}
            printError={this.state.printError}
            updatePrintError={this.updatePrintError}
            reportAddress={this.state.reportAddress}
          ></Filters>
          : null}
      </div>
    )
  }
}

export default GetReport;