import React from 'react';
import '../../../App.css';

const popupStyle = {
  fontFamily: 'Open Sans, Arial, sans-serif',
  minWidth: '200px',
  color: '#25408f'
}

const flexRow = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
}

class PopupContent extends React.Component {
  render() {
    const pointLocation = this.props.content.Address + ", " + this.props.content.City + ", " + this.props.content.State + " " + this.props.content.Zip;
    return (
      <div style={popupStyle}>
        <div className="chainText">
          {this.props.content.Chain}
        </div>
        <div className="addressText">
          {this.props.content.Address}
        </div>
        <div className="addressText">
          {this.props.content.City}, {this.props.content.State}  {this.props.content.Zip}
        </div>
        <div className="segmentDiv">
          <span className="segmentText">{this.props.content.Segment}</span>
          <span>{this.props.content.Subsegment}</span>
        </div>
        <div className="categoryDiv">
          {this.props.content.Category}
        </div>
        <div className="builtDiv">
          <div className="builtText">Built: {this.props.content['Year Store Built']}</div>
          <div className="ownerText">Ownership: {this.props.content['Company or Franchise']}</div>
        </div>
        <div className="currentDiv">
          <div className="currentText">Current</div><div className="width20">Past</div>
        </div>
        <div style={flexRow}>
          <div className="width60 darkBlueTxt">Natl Grade</div>
          <div className="width20 darkBlueTxt">{this.props.content['Current National Grade']}</div>
          <div className="width20 darkBlueTxt">{this.props.content['Past National Grade']}</div>
        </div>
        <div style={flexRow}>
          <div className="width60 lightBlueTxt">Natl Index</div>
          <div className="width20 lightBlueTxt">{this.props.content['Current National Index']}</div>
          <div className="width20 lightBlueTxt">{this.props.content['Past National Index']}</div>
        </div>
        <div style={flexRow}>
          <div className="width60 darkBlueTxt">Mkt Grade</div>
          <div className="width20 darkBlueTxt">{this.props.content['Current Market Grade']}</div>
          <div className="width20 darkBlueTxt">{this.props.content['Past Market Grade']}</div>
        </div>
        <div style={flexRow}>
          <div className="width60 lightBlueTxt">Mkt Index</div>
          <div className="width20 lightBlueTxt">{this.props.content['Current Market Index']}</div>
          <div className="width20 lightBlueTxt">{this.props.content['Past Market Index']}</div>
        </div>
        <div className="salesDiv">Sales: ${(this.props.content['Current Annual Sales ($000)']*1000).toLocaleString()}</div>
        {this.props.coordinates[1] && !this.props.mapAddPt ?
          <div className="geocodeDivSmall flexRight">
            <button id="popUsePointBtn" className="geocodeBtnSmall" value={JSON.stringify({position: this.props.coordinates, id: pointLocation})}>Use This Point</button>
          </div>
          : null
        }
      </div>
    );
  }
}

export default PopupContent;
