import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportTitleComponent from '../ReportTitleComponent/ReportTitleComponent';
import HierarchyTable from '../HierarchyTable/HierarchyTable';
import VariableDefs from '../VariableDefs/VariableDefs';

export default class MethodologyComponent extends Component {
  render() {
    const methodTitle = this.props.methodTitle || "Title Description";
    const id = 'panel2' + this.props.id + '-header' || "id";
    const aria = 'panel2' + this.props.id + '-content' || "content";
    const description = this.props.description || "description";
    const url = this.props.url || "#";
    const buttonText = this.props.buttonText || 'View Document';

    return (
      <Accordion className="innerExpansionPanel" square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={aria} id={id}>
          <span className="blueTitle">{methodTitle}</span>
        </AccordionSummary>
        <AccordionDetails>
          {buttonText === 'hierarchyTable' ?
            <HierarchyTable />
            :
            <div>
              {buttonText === 'variableTable' ?
                <VariableDefs />
                :
                <ReportTitleComponent
                  titleDescription={description}
                  whitePaperUrl={url}
                  buttonText={buttonText}
                />
              }
            </div>
          }
        </AccordionDetails>
      </Accordion>
    );
  }
}