import React, { Component } from 'react';

export default class VideoComponent extends Component {
  render() {
    const title = this.props.title || "Title";
    const src = this.props.src || "#";
    const type = this.props.type || "video/webm";

    return (
      <div className="method-grid-item">
        <div className="method-grid-item-container">
          <span className="blueTitle">{title}</span>
          <div className="summaryTxt">
            <video controls className="video-style">
              <source src={src}
                type={type} />

              Sorry, your browser doesn't support embedded videos.
                </video>
          </div>
        </div>
      </div>
    );
  }
}