import React from 'react';
import MainPage from './MainPage/MainPage';
import Support from './Support/Support';
import RouteError from './RouteError/RouteError';
import { useAuth0 } from './react-auth0-spa';
import FullStory, { FullStoryAPI as fullStoryAPI } from "react-fullstory";
import { Route, Switch } from 'react-router-dom';
import './App.css';
import './Mobile.css';

function App() {
  const { isAuthenticated, loginWithRedirect, loading, getTokenSilently, user } = useAuth0();

  function registerFullstory(user) {
    try {
      fullStoryAPI(
        "identify",
        user.id,
        {
          "displayName": user.name,
          "email": user.email,
          "tenant_strs": (user.tenants || []).map(tenant => tenant.name)
        }
      );
      } 
    catch (e) {} // eslint-disable-line
    }
  
  if (loading) {
    return <div className="loader"></div>;
  }

  if (isAuthenticated) {
    registerFullstory(user)

    getTokenSilently().then( result =>{
      localStorage.setItem('access_token', result);
    });

    localStorage.setItem('user_name', user.name);

    return (
      <Switch>
        <Route path="/" component={MainPage} exact />
        <Route path="/support" component={Support} />
        <Route component={RouteError} />
      </Switch>
    );
  } else {
    let redirectOptions = {};
    if ((window.location.pathname).includes('support')) {
      redirectOptions.redirect_uri = window.location.origin + window.location.pathname;
    }
    loginWithRedirect(redirectOptions);
    return (
      <div className="loader"></div>
    )
  }
}

export default App;
