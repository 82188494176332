import React from 'react';

class FilterInfo extends React.Component {
  backToFilterPanel() {
    this.props.toggleFilterInfo(false);
  }

  render() {
    return (
      <div className="filterInfoText">
        For example, if a 1 mile radius is selected, all of the possible options from within that 1 mile radius will appear in the dropdowns.  If Taco Bell, Pizza Hut, and Red Robin are the only restaurants within that 1 mile radius, they will be the only restaurants that appear in the Chains dropdown.
      </div>
    )
  }
}

export default FilterInfo;