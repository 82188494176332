import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const buttonStyle = {
  root: {
    background: '#ffffff',
    opacity: '0.9',
    border: 0,
    borderRadius: 0,
    color: '#25408f',
    height: 50,
    width: '98px',
    minWidth: '40px',
    padding: '0',
    '&:hover': {
      color: '#0c9ed9',
      borderLeft: '3px solid #0c9ed9'
    }
  }
}

const styles = {
  button: {
    background: '#ffffff',
    border: 0,
    borderRadius: 0,
    color: '#25408f',
    height: 50,
    width: '98px',
    minWidth: '40px',
    padding: '0',
    fontFamily: 'Open Sans',
    '&:hover': {
      color: '#0c9ed9',
    },
  },
  buttonBlue: {
    background: '#25408f',
    boxShadow: '1px 1px 3px 0px rgba(133,133,133,1)',
    height: 30,
    width: 'auto',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: '#ffffff'
  },
};

function PanelButton(props) {
  const { classes, children, className, handleClick, color, disabled, handleHover, handleHoverOff } = props;

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
      className={clsx(classes.root, className)}
      style={{
        ...(color === 'blue' ? styles.buttonBlue : {}),
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverOff}
    >{children}</Button>
  );
}

PanelButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  handleHover: PropTypes.func,
  handleHoverOff: PropTypes.func,
};

export default withStyles(buttonStyle)(PanelButton);