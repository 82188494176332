import React from 'react';
import '../../../App.css';
import axios from 'axios';
import FileSaver from 'file-saver';
import AutoCompFilters from '../AutoCompFilters/AutoCompFilters';
import FilterInfo from './FilterInfo/FilterInfo';

const questionMark = '?';

const apiToken = localStorage.getItem('access_token');

const headers = {
  'Authorization': 'Bearer ' + apiToken
};

//save the new request for cancellation
const _source = axios.CancelToken.source();

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chainData: [],
      pointData: [],
      currentFilterData: [],
      segmentOptions: [],
      subsegmentOptions: [],
      categoryOptions: [],
      chainOptions: [],
      showErrorMessage: false,
      filtersLoading: true,
      loadingFiltersText: true,
      loadingReportText: false,
      errorMessageText: 'Oops something went wrong.',
      editingReportName: false,
      currentTitle: 'New Report',
      prevSegmentFilterVal: this.props.segmentFilterVal,
      prevSubsegmentFilterVal: this.props.subsegmentFilterVal,
      prevCategoryFilterVal: this.props.categoryFilterVal,
      prevChainFilterVal: this.props.chainFilterVal,
      prevMinSalesVal: this.props.minSalesFilterVal,
      prevMaxSalesVal: this.props.maxSalesFilterVal,
      filterHidden: false,
      prevMapMoved: this.props.mapMoved,
      reportButtonActive: true,
      salesActive: true,
      showFilterInfo: false,
      over200: false,
      prevPrintError: false
    }

    this.backToRadius = this.backToRadius.bind(this);
    this.getReport = this.getReport.bind(this);
    this.getNewQuery = this.getNewQuery.bind(this);
    this.resetFilterVals = this.resetFilterVals.bind(this);
    this.getCustomPolyQuery = this.getCustomPolyQuery.bind(this);
    this.editReportName = this.editReportName.bind(this);
    this.saveReportName = this.saveReportName.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.onCheckboxClear = this.onCheckboxClear.bind(this);
    this.onTagRemoved = this.onTagRemoved.bind(this);
    this.print = this.print.bind(this);
    this.toggleFilterHidden = this.toggleFilterHidden.bind(this);
    this.toggleFilterInfo = this.toggleFilterInfo.bind(this);
    this.updateCurrentPointData = this.updateCurrentPointData.bind(this);
    this._getInitialFiltersAxiosBody = this._getInitialFiltersAxiosBody.bind(this);
    this._getReportAxiosBody = this._getReportAxiosBody.bind(this);
    this._getReportAxiosError = this._getReportAxiosError.bind(this);
  }

  componentDidMount() {
    this.getInitialFilterVals(true);
  }

  getInitialFilterVals(first, currentQueryObj) {
    let currentObj = currentQueryObj || {postObj: {}, getString: "?"};
    const initialLoad = first;
    const newQueryObj = this._getGeogQueryString(currentObj);

    //cancel previous request
    if (typeof this._source != typeof undefined) {
      this._source.cancel('Operation canceled due to new request.');
    }

    //save the new request for cancellation
    this._source = axios.CancelToken.source();

    if (this.props.driveTime) {
      axios.post(process.env.REACT_APP_APIURL + "/points", newQueryObj.postObj, { headers: headers }, { cancelToken: this._source.token })
        .then((chains) => {
          this._getInitialFiltersAxiosBody(chains, initialLoad);
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.setState({
              showErrorMessage: true,
              filtersLoading: false,
              reportButtonActive: false
            });
            console.log('Request canceled', error);
          } else {
            this.setState({
              showErrorMessage: true,
              filtersLoading: false,
              reportButtonActive: false
            });
            console.log(error);
          }
        });
    } else {
      axios.get(process.env.REACT_APP_APIURL + "/points" + newQueryObj.getString, { headers: headers }, { cancelToken: this._source.token })
        .then((chains) => {
          this._getInitialFiltersAxiosBody(chains, initialLoad);
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.setState({
              showErrorMessage: true,
              filtersLoading: false,
              reportButtonActive: false
            });
            console.log('Request canceled', error);
          } else {
            this.setState({
              showErrorMessage: true,
              filtersLoading: false,
              reportButtonActive: false
            });
            console.log(error);
          }
        });
    }
  }

  _getInitialFiltersAxiosBody(chains, initialLoad) {
    if (chains) {
      let reportActiveBool = true;
      let showErrorMessageBool = false;
      let errorMessageText = 'Oops something went wrong.';
      let salesActiveBool = true;
      let over200Bool = this.state.over200;
      let chainsFinalArray = chains.data;
      let pointDataClone = Array.from(this.state.pointData);
      let currentFilterDataClone = Array.from(this.state.currentFilterData);
      if (chainsFinalArray.length >= 201) {
        reportActiveBool = false;
        salesActiveBool = false;
        showErrorMessageBool = true;
        if (initialLoad) {
          over200Bool = true;
        }
        errorMessageText = 'More than 200 records returned in search. Use filters to narrow search and activate sales filter.';
      } else if (chainsFinalArray.length <= 200 && !initialLoad) {
        for (const chain of chainsFinalArray) {
          for (const point of pointDataClone) {
            if (point.id === chain.id) {
              point.Sales = chain.Sales;
              break;
            }
          }

          for (const currentFilter of currentFilterDataClone) {
            if (currentFilter.id === chain.id) {
              currentFilter.Sales = chain.Sales;
              break;
            }
          }
        }
      }

      const distinctChains = chainsFinalArray.filter(
        (thing, i, arr) => arr.findIndex(t => t.Chain === thing.Chain) === i
      );

      let updateObj = {
        pointData: pointDataClone,
        currentFilterData: currentFilterDataClone,
        filtersLoading: false,
        loadingFiltersText: false,
        reportButtonActive: reportActiveBool,
        showErrorMessage: showErrorMessageBool,
        errorMessageText: errorMessageText,
        salesActive: salesActiveBool,
        over200: over200Bool
      }

      if (initialLoad) {
        updateObj.pointData = chainsFinalArray;
        updateObj.currentFilterData = chainsFinalArray;
        updateObj.chainData = distinctChains;
        updateObj.segmentOptions = this.createOptionArray(chainsFinalArray, 'Segment');
        updateObj.subsegmentOptions = this.createOptionArray(chainsFinalArray, 'SubSegment');
        updateObj.categoryOptions = this.createOptionArray(chainsFinalArray, 'Category');
        updateObj.chainOptions = this.createOptionArray(chainsFinalArray, 'Chain');
      }

      this.setState(updateObj);
    }
  }

  componentDidUpdate() {
    let segmentArrayEqual = this.state.prevSegmentFilterVal.length === this.props.segmentFilterVal.length && this.state.prevSegmentFilterVal.every(item => this.props.segmentFilterVal.indexOf(item) > -1);
    let subsegmentArrayEqual = this.state.prevSubsegmentFilterVal.length === this.props.subsegmentFilterVal.length && this.state.prevSubsegmentFilterVal.every(item => this.props.subsegmentFilterVal.indexOf(item) > -1);
    let categoryArrayEqual = this.state.prevCategoryFilterVal.length === this.props.categoryFilterVal.length && this.state.prevCategoryFilterVal.every(item => this.props.categoryFilterVal.indexOf(item) > -1);
    let chainArrayEqual = this.state.prevChainFilterVal.length === this.props.chainFilterVal.length && this.state.prevChainFilterVal.every(item => this.props.chainFilterVal.indexOf(item) > -1);

    if (segmentArrayEqual === false || subsegmentArrayEqual === false || categoryArrayEqual === false || chainArrayEqual === false) {

      let type = 'chain';
      if (this.state.prevSegmentFilterVal !== this.props.segmentFilterVal) {
        type = 'segment';
      } else if (this.state.prevSubsegmentFilterVal !== this.props.subsegmentFilterVal) {
        type = 'subsegment';
      } else if (this.state.prevCategoryFilterVal !== this.props.categoryFilterVal) {
        type = 'category';
      }

      this.getNewFilterVals(type);
    }

    if (this.state.prevMinSalesVal !== this.props.minSalesFilterVal) {
      this.updateCurrentPointData('minSales', this.props.minSalesFilterVal);
    }

    if (this.state.prevMaxSalesVal !== this.props.maxSalesFilterVal) {
      this.updateCurrentPointData('maxSales', this.props.maxSalesFilterVal);
    }

    if (this.props.mapMoved !== this.state.prevMapMoved && this.props.methodType === 'print' && this.state.filterHidden === false) {
      this.setState({
        filterHidden: true,
        prevMapMoved: this.props.mapMoved
      });
      this.props.updateHideFilterClass(true);
    }

    if (this.props.printError !== this.state.prevPrintError) {
      if (this.props.printError) {
        this.setState({
          errorMessageText: 'Oops something went wrong.',
          prevPrintError: true
        });
      }
    }
  }

  updateCurrentPointData(type, newValue) {
    let currentFilterDataClone = this.state.currentFilterData;
    let updateObj = {
      currentFilterData: currentFilterDataClone
    };
    let newFilterData = [];
    if (type === 'minSales' && newValue !== null) {
      newFilterData = currentFilterDataClone.filter(element => (this._formatSalesToInt(element.Sales) >= newValue * 1000000) && (this._formatSalesToInt(element.Sales) <= this.props.maxSalesFilterVal * 1000000));
    } else {
      newFilterData = currentFilterDataClone.filter(element => (this._formatSalesToInt(element.Sales) >= this.props.minSalesFilterVal * 1000000) && (this._formatSalesToInt(element.Sales) <= newValue * 1000000));
    }

    updateObj.reportButtonActive = true;
    updateObj.showErrorMessage = false;
    updateObj.errorMessageText = 'Oops something went wrong.';
    //updateObj.currentFilterData = newFilterData;
    updateObj.prevMinSalesVal = this.props.minSalesFilterVal;
    updateObj.prevMaxSalesVal = this.props.maxSalesFilterVal;
    if (newFilterData.length >= 201) {
      updateObj.reportButtonActive = false;
      updateObj.showErrorMessage = true;
      updateObj.errorMessageText = 'More than 200 records returned in search. Use filters to narrow search.';
    }

    this.setState(updateObj);
  }

  toggleFilterHidden() {
    this.setState({
      filterHidden: false
    });
    this.props.updateHideFilterClass(false);
    this.getInitialFilterVals(true);
  }

  editReportName() {
    this.setState({
      editingReportName: true
    });
  }

  setTitle(e) {
    this.setState({
      currentTitle: e.target.value
    });
  }

  saveReportName() {
    let updateObject = {};
    if (this.state.currentTitle === '') {
      updateObject.currentTitle = 'New Report';
    }

    updateObject.editingReportName = false;

    this.setState(updateObject);

    this.props.updateReportName(this.state.currentTitle);
  }

  getCustomPolyQuery(type) {
    const polysClone = Array.from(this.props.polys);
    let latLngObj;

    if (type === 'rectangle') {
      latLngObj = {
        north: Math.max.apply(Math, polysClone[0].position[0].map(function (i) { return i.lat; })),
        south: Math.min.apply(Math, polysClone[0].position[0].map(function (i) { return i.lat; })),
        east: Math.max.apply(Math, polysClone[0].position[0].map(function (i) { return i.lng; })),
        west: Math.min.apply(Math, polysClone[0].position[0].map(function (i) { return i.lng; })),
      };
    } else if (type === 'print') {
      latLngObj = {
        north: this.props.mapBounds._northEast.lat,
        south: this.props.mapBounds._southWest.lat,
        east: this.props.mapBounds._northEast.lng,
        west: this.props.mapBounds._southWest.lng,
      };
    } else if (type === 'polygon') {
      let coordString = '';
      let firstNodeBool = true;
      let firstNode = '';
      polysClone[0].position[0].forEach(position => {
        if (firstNodeBool) {
          firstNode = position.lng + " " + position.lat;
          firstNodeBool = false;
        }
        coordString = coordString + position.lng + " " + position.lat + ",";
      });

      latLngObj = coordString + firstNode
    } else if (type === 'drivetime') {
      let coordString = '';
      var newPoints = [...polysClone[0].position];
      var polyReverse = Array.from(newPoints.reverse());
      polyReverse.forEach(position => {
        coordString = coordString + position[1] + " " + position[0] + ",";
      });

      latLngObj = coordString.slice(0, -1);
    }

    return latLngObj;
  }

  createOptionArray(array, key) {
    let newArray = Array.from(array);
    let result = newArray.map(a => a[key]);

    var uniqueArrayNotSorted = [...new Set(result)];
    var uniqueArray = uniqueArrayNotSorted.sort();

    return uniqueArray;
  }

  setMinSales(e) {
    this.props.updateFilterVal([{ type: 'minSales', add: true, value: e.target.value }]);
  }

  setMaxSales(e) {
    this.props.updateFilterVal([{ type: 'maxSales', add: true, value: e.target.value }])
  }

  getNewQuery(type, add, value) {
    let segmentFilterVal = Array.from(this.props.segmentFilterVal);
    let subsegmentFilterVal = Array.from(this.props.subsegmentFilterVal);
    let categoryFilterVal = Array.from(this.props.categoryFilterVal);
    let chainFilterVal = Array.from(this.props.chainFilterVal);

    if (type === 'segment') {
      if (add) {
        segmentFilterVal.push(value);
      } else {
        segmentFilterVal = segmentFilterVal.filter(item => item !== value);
      }
    } else if (type === 'subsegment') {
      if (add) {
        subsegmentFilterVal.push(value);
      } else {
        subsegmentFilterVal = subsegmentFilterVal.filter(item => item !== value);
      }
    } else if (type === 'category') {
      if (add) {
        categoryFilterVal.push(value);
      } else {
        categoryFilterVal = categoryFilterVal.filter(item => item !== value);
      }
    }

    let combinedUpdateArray = this._getCombinedUpdateArray(type, segmentFilterVal, subsegmentFilterVal, categoryFilterVal, chainFilterVal);

    combinedUpdateArray.push({ type: type, add: add, value: value });

    this.props.updateFilterVal(combinedUpdateArray);
  }

  _getCombinedUpdateArray(type, segmentFilterVal, subsegmentFilterVal, categoryFilterVal, chainFilterVal) {
    let segmentArray;
    let subsegmentArray;
    let categoryArray;
    let chainDataClone = Array.from(this.state.chainData);
    let combinedUpdateArray = [];


    if (segmentFilterVal.length > 0) {
      segmentArray = chainDataClone.filter(element => segmentFilterVal.includes(element.Segment));
    } else {
      segmentArray = Array.from(chainDataClone);
    }

    if (subsegmentFilterVal.length > 0) {
      subsegmentArray = segmentArray.filter(element => subsegmentFilterVal.includes(element.SubSegment));
    } else {
      subsegmentArray = Array.from(segmentArray);
    }

    if (categoryFilterVal.length > 0) {
      categoryArray = subsegmentArray.filter(element => categoryFilterVal.includes(element.Category));
    } else {
      categoryArray = Array.from(subsegmentArray);
    }

    if (type === 'segment') {
      if (subsegmentFilterVal.length > 0) {
        const propertyArray = segmentArray.map(item => item.SubSegment);
        const updateArray = this._getRemoveValues(subsegmentFilterVal, propertyArray, 'subsegment');
        combinedUpdateArray = combinedUpdateArray.concat(updateArray);
      }

      if (categoryFilterVal.length > 0) {
        const propertyArray = segmentArray.map(item => item.Category);
        const updateArray = this._getRemoveValues(categoryFilterVal, propertyArray, 'category');
        combinedUpdateArray = combinedUpdateArray.concat(updateArray);
      }

      if (chainFilterVal.length > 0) {
        const propertyArray = segmentArray.map(item => item.Chain);
        const updateArray = this._getRemoveValues(chainFilterVal, propertyArray, 'chain');
        combinedUpdateArray = combinedUpdateArray.concat(updateArray);
      }
    } else if (type === 'subsegment') {
      if (categoryFilterVal.length > 0) {
        const propertyArray = subsegmentArray.map(item => item.Category);
        const updateArray = this._getRemoveValues(categoryFilterVal, propertyArray, 'category');
        combinedUpdateArray = combinedUpdateArray.concat(updateArray);
      }

      if (chainFilterVal.length > 0) {
        const propertyArray = subsegmentArray.map(item => item.Chain);
        const updateArray = this._getRemoveValues(chainFilterVal, propertyArray, 'chain');
        combinedUpdateArray = combinedUpdateArray.concat(updateArray);
      }
    } else if (type === 'category') {
      if (chainFilterVal.length > 0) {
        const propertyArray = categoryArray.map(item => item.Chain);
        const updateArray = this._getRemoveValues(chainFilterVal, propertyArray, 'chain');
        combinedUpdateArray = combinedUpdateArray.concat(updateArray);
      }
    }

    return combinedUpdateArray;
  }

  _getRemoveValues(filterValueArray, typeArray, type) {
    let updateArray = [];
    const removeArray = filterValueArray.filter(e => !typeArray.includes(e));
    removeArray.forEach(item => {
      updateArray.push({ type: type, add: false, value: item })
    });

    return updateArray;
  }

  getNewFilterVals(type) {
    const segmentFilterVal = Array.from(this.props.segmentFilterVal);
    const subsegmentFilterVal = Array.from(this.props.subsegmentFilterVal);
    const categoryFilterVal = Array.from(this.props.categoryFilterVal);
    const chainFilterVal = Array.from(this.props.chainFilterVal);

    let segmentArray, subsegmentArray, categoryArray;
    let segmentPtArray, subsegmentPtArray, categoryPtArray, chainPtArray;
    let chainDataClone = Array.from(this.state.chainData);
    let pointDataClone = Array.from(this.state.pointData);

    if (segmentFilterVal.length > 0) {
      segmentArray = chainDataClone.filter(element => segmentFilterVal.includes(element.Segment));
      segmentPtArray = pointDataClone.filter(element => segmentFilterVal.includes(element.Segment));
    } else {
      segmentArray = Array.from(chainDataClone);
      segmentPtArray = Array.from(pointDataClone);
    }

    if (subsegmentFilterVal.length > 0) {
      subsegmentArray = segmentArray.filter(element => subsegmentFilterVal.includes(element.SubSegment));
      subsegmentPtArray = segmentPtArray.filter(element => subsegmentFilterVal.includes(element.SubSegment));
    } else {
      subsegmentArray = Array.from(segmentArray);
      subsegmentPtArray = Array.from(segmentPtArray);
    }

    if (categoryFilterVal.length > 0) {
      categoryArray = subsegmentArray.filter(element => categoryFilterVal.includes(element.Category));
      categoryPtArray = subsegmentPtArray.filter(element => categoryFilterVal.includes(element.Category));
    } else {
      categoryArray = Array.from(subsegmentArray);
      categoryPtArray = Array.from(subsegmentPtArray);
    }

    if (chainFilterVal.length > 0) {
      chainPtArray = categoryPtArray.filter(element => chainFilterVal.includes(element.Chain))
    } else {
      chainPtArray = Array.from(categoryPtArray);
    }

    let updateObj = {
      prevSegmentFilterVal: this.props.segmentFilterVal,
      prevSubsegmentFilterVal: this.props.subsegmentFilterVal,
      prevCategoryFilterVal: this.props.categoryFilterVal,
      prevChainFilterVal: this.props.chainFilterVal
    }

    if (type === 'segment') {
      updateObj.chainOptions = this.createOptionArray(categoryArray, 'Chain');
      updateObj.categoryOptions = this.createOptionArray(categoryArray, 'Category');
      updateObj.subsegmentOptions = this.createOptionArray(categoryArray, 'SubSegment');
    } else if (type === 'subsegment') {
      updateObj.chainOptions = this.createOptionArray(categoryArray, 'Chain');
      updateObj.categoryOptions = this.createOptionArray(categoryArray, 'Category');
    } else if (type === "category") {
      updateObj.chainOptions = this.createOptionArray(categoryArray, 'Chain');
    }

    updateObj.reportButtonActive = true;
    updateObj.salseActive = true;
    updateObj.showErrorMessage = false;
    updateObj.errorMessageText = 'Oops something went wrong.';
    updateObj.currentFilterData = chainPtArray;

    this.setState(updateObj);

    if (this.state.over200) {
      this.setState({
        filtersLoading: true,
        loadingFiltersText: true
      });
      this.setMaxSales({target: {value: 50}});
      this.setMinSales({target: {value: 0}});
      const filterQueryObj = this._getFilterQueryString(false, true);
      this.getInitialFilterVals(false, filterQueryObj);
    }
  }

  onTagRemoved(type, newValArray) {
    let segmentFilterVal = Array.from(this.props.segmentFilterVal);
    let subsegmentFilterVal = Array.from(this.props.subsegmentFilterVal);
    let categoryFilterVal = Array.from(this.props.categoryFilterVal);
    let chainFilterVal = Array.from(this.props.chainFilterVal);

    if (type === 'segment')
      segmentFilterVal = newValArray;
    if (type === 'subsegment')
      subsegmentFilterVal = newValArray;
    if (type === 'category')
      categoryFilterVal = newValArray;
    if (type === 'chain')
      chainFilterVal = newValArray;

    let combinedUpdateArray = this._getCombinedUpdateArray(type, segmentFilterVal, subsegmentFilterVal, categoryFilterVal, chainFilterVal);

    this.props.updateFilterVal(combinedUpdateArray);
  }

  onCheckboxClear(type) {
    this.props.updateFilterVal([{ type: type, add: false, value: 'resetVal' }]);
  }

  getReport() {
    let filterQueryObj = this._getFilterQueryString();
    let finalQueryObj = this._getGeogQueryString(filterQueryObj, false, true);
    const usePost = this.props.driveTime ? true : false;
    this.props.updatePrintError(false);

    this.setState({
      showErrorMessage: false,
      loadingReportText: true
    });

    //cancel previous request
    if (typeof _source != typeof undefined) {
      _source.cancel('Operation canceled due to new request.');
    }

    if (usePost) {
      axios.post(process.env.REACT_APP_APIURL + "/report", finalQueryObj.postObj, { headers: headers, responseType: 'blob' }, { cancelToken: _source.token })
        .then(result => {
          this._getReportAxiosBody(result);
        }).catch(error => {
          this._getReportAxiosError(error);
        });
    } else {
      axios.get(process.env.REACT_APP_APIURL + "/report" + finalQueryObj.getString, { headers: headers, responseType: 'blob' }, { cancelToken: this._source.token })
        .then(result => {
          this._getReportAxiosBody(result);
        }).catch(error => {
          this._getReportAxiosError(error);
        });
    }
  }

  _getReportAxiosBody(result) {
    if (result && result.statusText === "OK") {
      if (result.data.type.includes("application/json")) {
        this.setState({
          showErrorMessage: true,
          loadingReportText: false,
          errorMessageText: 'No records returned from search criteria.'
        });
      } else {
        FileSaver.saveAs(result.data, 'restaurant_bytes.pdf');
        this.setState({
          loadingReportText: false
        });
      }
    } else {
      this.setState({
        showErrorMessage: true,
        loadingReportText: false,
        errorMessageText: 'Oops! Something went wrong. Please try again.'
      });
    }
  }

  _getReportAxiosError(error) {
    if (error.response && error.response.status === 400) {
      this.setState({
        showErrorMessage: true,
        loadingReportText: false,
        errorMessageText: 'Too many records (over 200) returned. Please adjust your search criteria.'
      });
    } else {
      this.setState({
        showErrorMessage: true,
        loadingReportText: false,
        errorMessageText: 'Oops! Something went wrong. Please try again.'
      });
    }
  }

  _getFilterQueryString(report, update) {
    let segmentQuery = "";
    let subsegmentQuery = "";
    let categoryQuery = "";
    let chainQuery = "";
    let salesQuery = "";
    let combinedQuery = "";
    let filterReportDesc = "";
    let queryObj = {
      reportname: encodeURIComponent(this.props.reportName)
    };

    if (this.props.segmentFilterVal.length > 0) {
      const segmentString = Array.from(this.props.segmentFilterVal).join('|');
      segmentQuery = "segment=" + segmentString;
      combinedQuery = combinedQuery + segmentQuery + "&";
      filterReportDesc = segmentString;

      queryObj.segment = segmentString;
    }

    if (this.props.subsegmentFilterVal.length > 0) {
      const subsegmentString = Array.from(this.props.subsegmentFilterVal).join('|');
      subsegmentQuery = "subsegment=" + subsegmentString;
      combinedQuery = combinedQuery + subsegmentQuery + "&";
      if (filterReportDesc !== "") {
        filterReportDesc = filterReportDesc + ", ";
      }
      filterReportDesc = filterReportDesc + subsegmentString;

      queryObj.subsegment = subsegmentString;
    }

    if (this.props.categoryFilterVal.length > 0) {
      const categoryString = Array.from(this.props.categoryFilterVal).join('|');
      categoryQuery = "category=" + categoryString;
      combinedQuery = combinedQuery + categoryQuery + "&";
      if (filterReportDesc !== "") {
        filterReportDesc = filterReportDesc + ", ";
      }
      filterReportDesc = filterReportDesc + categoryString;

      queryObj.category = categoryString;
    }

    if (this.props.chainFilterVal.length > 0) {
      let encodeArray = [];
      const chainArrayClone = Array.from(this.props.chainFilterVal);
      chainArrayClone.forEach(chain => {
        const encodeChain = encodeURIComponent(chain);
        encodeArray.push(encodeChain);
      });
      const chainString = encodeArray.join('|');
      chainQuery = "chain=" + chainString;
      combinedQuery = combinedQuery + chainQuery + "&";
      if (filterReportDesc !== "") {
        filterReportDesc = filterReportDesc + ", ";
      }
      filterReportDesc = filterReportDesc + chainString;

      queryObj.chain = chainString;
    }

    if (filterReportDesc !== "") {
      filterReportDesc = filterReportDesc + " dining locations";
    }

    if (!update) {
      if (this.props.minSalesFilterVal > 0 || this.props.maxSalesFilterVal < 50) {
        salesQuery = "minsalesk=" + this.props.minSalesFilterVal * 1000 + "&maxsalesk=" + this.props.maxSalesFilterVal * 1000;
        combinedQuery = combinedQuery + salesQuery + "&";
  
        queryObj.minsalesk = this.props.minSalesFilterVal * 1000;
        queryObj.maxsalesk = this.props.maxSalesFilterVal * 1000;
  
        if (filterReportDesc === "") {
          filterReportDesc = "dining locations "
        }
        filterReportDesc = filterReportDesc + " where annual sales are between $" + this.props.minSalesFilterVal + "MM and $" + this.props.maxSalesFilterVal + "MM";
      }
    }

    if (filterReportDesc === "") {
      filterReportDesc = "all dining locations"
    }

    if (report) {
      combinedQuery = '';
    }

    filterReportDesc = filterReportDesc.replace(/\|/g, ", ");
    filterReportDesc = filterReportDesc + ".";
    const filterQueryObj = {
      postObj: queryObj,
      getString: questionMark + 'reportname=' + encodeURIComponent(this.props.reportName) + '&' + combinedQuery,
      filterReportDesc: encodeURIComponent(filterReportDesc)
    }

    return filterQueryObj;
  }

  _getGeogQueryString(queryObj, print, report) {
    let newQueryObj = queryObj.postObj;
    let geogQueryString = queryObj.getString;
    let reportGeogQString = queryObj.getString;
    let geogReportDesc = '';

    if (this.props.methodType === 'typeAddress' || this.props.methodType === 'clickMap' || this.props.methodType === 'restPoint') {
      if (this.props.driveTime) {
        const latLngObj = this.getCustomPolyQuery('drivetime');
        newQueryObj.polygonstring = latLngObj;
        geogReportDesc = geogReportDesc + this.props.radiusVal + " minute drivetime from " + this.props.reportAddress + " showing ";
      } else {
        newQueryObj.radius = this.props.radiusVal.toString();
        newQueryObj.latitude = this.props.points[0].position.lat.toString();
        newQueryObj.longitude = this.props.points[0].position.lng.toString();
        geogQueryString += 'radius=' + this.props.radiusVal + '&latitude=' + this.props.points[0].position.lat + '&longitude=' + this.props.points[0].position.lng;
        geogReportDesc = geogReportDesc + this.props.radiusVal + " mile radius from " + this.props.reportAddress + " showing ";
      }
    } else if (this.props.methodType === 'stndGeo') {
      const geogObj = this._getStndGeoPostType();
      newQueryObj[geogObj.type] = geogObj.value;
      geogQueryString += this.props.geogQuery;
      geogReportDesc = geogReportDesc + this.props.reportAddress + " showing ";
    } else if (this.props.methodType === 'draw') {
      const type = this.props.polys[0].id;
      let latLngObj = {};
      if (type === 'rectangle' && !print) {
        latLngObj = this.getCustomPolyQuery('rectangle');
        geogQueryString += 'north=' + latLngObj.north + '&south=' + latLngObj.south + '&east=' + latLngObj.east + '&west=' + latLngObj.west;
        geogReportDesc = "Custom polygon showing ";
      } else if (type === 'polygon' || print) {
        latLngObj = this.getCustomPolyQuery('polygon');
        newQueryObj.polygonstring = latLngObj;
        geogQueryString += 'polygonstring=' + latLngObj;
        geogReportDesc = "Custom polygon showing ";
      } else {
        console.log("Invalid polygon type.");
      }
    } else if (this.props.methodType === 'print') {
      const printObj = this.getCustomPolyQuery('print');
      geogQueryString += 'north=' + printObj.north + '&south=' + printObj.south + '&east=' + printObj.east + '&west=' + printObj.west;
      geogReportDesc = "Map area showing ";
    }

    //report description and username
    const finalReportDesc = geogReportDesc + queryObj.filterReportDesc;
    const userName = localStorage.getItem('user_name');
    geogQueryString = geogQueryString + "&reportdesc=" + finalReportDesc + "&username=" + userName;
    newQueryObj.reportdesc = finalReportDesc;
    newQueryObj.username = userName;

    if (report) {
      let currentFilterDataClone = Array.from(this.state.currentFilterData);
      if (this.state.currentFilterData.length === 0) {
        currentFilterDataClone = Array.from(this.state.pointData);
      }

      const currentFilterMaxSales = currentFilterDataClone.filter(point => this._formatSalesToInt(point.Sales) <= this.props.maxSalesFilterVal * 1000000);
      const currentFilterMinSales = currentFilterMaxSales.filter(point => this._formatSalesToInt(point.Sales) >= this.props.minSalesFilterVal * 1000000);
      const currentFilterFinal = currentFilterMinSales.map(a => a.id);
      const idString = currentFilterFinal.join('|');
      newQueryObj.ids = idString;
      reportGeogQString = reportGeogQString + "ids=" + idString + "&reportdesc=" + finalReportDesc + "&username=" + userName;
    }

    const finalQueryObj = {
      postObj: queryObj.postObj,
      getString: report ? reportGeogQString : geogQueryString,
    };

    return finalQueryObj;
  }

  _formatSalesToInt(sales) {
    if (sales && isNaN(sales)) {
      const removeDollarSign = sales.replace('$','');
      const removeCommas = removeDollarSign.replace(/,/g, '');
      let newInt = parseInt(removeCommas);
  
      if (isNaN(newInt)) {
        newInt = 0;
      }
      return newInt;
    } else {
      return sales;
    }    
  }

  _getStndGeoPostType() {
    const stringArray = this.props.geogQuery.split('=');
    const typeObj = {
      type: stringArray[0],
      value: stringArray[1]
    };
    return typeObj;
  }

  backToRadius() {
    setTimeout(() => {
      this.props.hideFilters();
    }, 200);
  }

  toggleFilterInfo() {
    this.setState({
      showFilterInfo: !this.state.showFilterInfo
    });
  }

  print() {
    const filterQueryObj = this._getFilterQueryString();
    let finalQueryObj = this._getGeogQueryString(filterQueryObj, true, false);
    this.setState({
      prevPrintError: false
    });
    this.props.printMap(finalQueryObj.postObj);
  }

  componentWillUnmount() {
    this.resetFilterVals();
    this.props.updatePrintError(false);
  }

  resetFilterVals() {
    this.props.updateFilterVal([{ type: 'reset', add: true, value: '' }]);
  }

  render() {
    var hideStyleClass = this.state.filterHidden ? 'filterShow' : 'filterHide';
    var mainStyleClass = this.state.filterHidden ? 'filterHide' : 'filterShow';
    var hideLoadingClass = this.state.filtersLoading ? 'filterShow' : 'filterHide';
    var mainLoadingClass = this.state.filtersLoading ? 'filterHide' : 'filterShow';

    return (
      <div>
        <div className={hideStyleClass}>
          <button className="geocodeBtn" onClick={this.toggleFilterHidden}>Show Filters</button>
        </div>
        <div className={mainStyleClass}>
          <h2 className="titleH2">
            <span className="paddingR10">
              <svg id="filterIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 92.51 88.06">
                <path id="Path_3594" data-name="Path 3594" d="M-8.28,60.9A26.83,26.83,0,0,1-35.11,34.07,26.84,26.84,0,0,1-8.28,7.23,26.84,26.84,0,0,1,18.56,34.06h0A26.87,26.87,0,0,1-8.28,60.9Z" transform="translate(36.36 25.89)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_3595" data-name="Path 3595" d="M41.5,57.3A26.86,26.86,0,1,1,53.31,43.16" transform="translate(36.36 25.89)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_3596" data-name="Path 3596" d="M10,29A26.84,26.84,0,1,1,36.78,2.19h0A26.87,26.87,0,0,1,10,29Z" transform="translate(36.36 25.89)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              </svg>
            </span>
                Set Filters
              </h2>
          <div className={hideLoadingClass}>
            <div className="filter-loader"></div>
            {
              this.state.loadingFiltersText ?
                <div className="loadingText">Loading filters...</div>
                : null
            }
            {/* {
              this.state.loadingReportText ?
                <div style={loadingText}>Please wait while your report downloads.</div>
                : null
            } */}
          </div>
          <div className={mainLoadingClass}>
            <div className="rptTitleDiv">
              <div className="rptTitleTxt1">Report Title:</div>
              {this.state.editingReportName ?
                <div className="flexCenter">
                  <div className="rptTitleTxt2">
                    <input
                      id="rptName-input"
                      className="rptNameInput"
                      defaultValue={this.props.reportName}
                      onChange={e => this.setTitle(e)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.saveReportName(e)
                        }
                      }}
                      onBlur={(e) => { this.saveReportName(e) }}
                    />
                  </div>
                  <div style={{ marginTop: '-7px' }}>
                    <button className="geocodeBtnSmall" onClick={this.saveReportName}>Save</button>
                  </div>
                </div>
                :
                <div className="flexCenter">
                  <div className="rptTitleTxt2">
                    {this.props.reportName}
                  </div>
                  <div style={{ marginTop: '-3px' }}>
                    <button className="geocodeBtnSmall" onClick={this.editReportName}>Edit</button>
                  </div>
                </div>
              }
            </div>
            <div>
              <div className="filterExplanation">
                <div>*Filter options reflect available values within selected geometry</div>
                <div className="paddingL15">
                  <button className="geocodeBtnSmall" onClick={this.toggleFilterInfo}>{this.state.showFilterInfo ? 'Less' : 'More'}</button>
                </div>
              </div>
              {this.state.showFilterInfo ?
                <FilterInfo />
                : null
              }
              <div className="filterSalesDiv">
                <div className="filterLabel">Segment:</div>
                <div className="filterSelectDiv">
                  <AutoCompFilters
                    key={'segmentKey'}
                    id={'autoCompSegment'}
                    type={'segment'}
                    options={this.state.segmentOptions}
                    label={'Segments'}
                    getNewQuery={this.getNewQuery}
                    onClear={this.onCheckboxClear}
                    selections={this.props.segmentFilterVal || []}
                    updateFilterVal={this.props.updateFilterVal}
                    onTagRemoved={this.onTagRemoved}
                  />
                </div>
              </div>
              <div className="filterSalesDiv">
                <div className="filterLabel">Subsegment:</div>
                <div className="filterSelectDiv">
                  <AutoCompFilters
                    key={'subsegmentKey'}
                    id={'autoCompSubsegment'}
                    type={'subsegment'}
                    options={this.state.subsegmentOptions}
                    label={'Subsegments'}
                    getNewQuery={this.getNewQuery}
                    onClear={this.onCheckboxClear}
                    selections={this.props.subsegmentFilterVal || []}
                    updateFilterVal={this.props.updateFilterVal}
                    onTagRemoved={this.onTagRemoved}
                  />
                </div>
              </div>
              <div className="filterSalesDiv">
                <div className="filterLabel">Category:</div>
                <div className="filterSelectDiv">
                  <AutoCompFilters
                    key={'categoryKey'}
                    id={'autoCompCategory'}
                    type={'category'}
                    options={this.state.categoryOptions}
                    label={'Categories'}
                    getNewQuery={this.getNewQuery}
                    onClear={this.onCheckboxClear}
                    selections={this.props.categoryFilterVal || []}
                    updateFilterVal={this.props.updateFilterVal}
                    onTagRemoved={this.onTagRemoved}
                  />
                </div>
              </div>
              <div className="filterSalesDiv">
                <div className="filterLabel">Chain:</div>
                <div className="filterSelectDiv">
                  <AutoCompFilters
                    key={'chainKey'}
                    id={'autoCompChain'}
                    type={'chain'}
                    options={this.state.chainOptions}
                    label={'Chains'}
                    getNewQuery={this.getNewQuery}
                    onClear={this.onCheckboxClear}
                    selections={this.props.chainFilterVal || []}
                    updateFilterVal={this.props.updateFilterVal}
                    onTagRemoved={this.onTagRemoved}
                  />
                </div>
              </div>
              {this.state.salesActive ?
                <div className="filterSalesDiv">
                  <div className="filterLabel">Annual Sales:</div>
                  <div className="filterSelectDiv">
                    <span>$</span>
                    <div>
                      <input
                        id="minSales-input"
                        min="0"
                        max={this.props.maxSalesFilterVal - 1}
                        className="salesInput"
                        value={this.props.minSalesFilterVal}
                        type="number"
                        onChange={(e) => { this.setMinSales(e) }}
                      />
                    </div>
                    <span>MM</span>
                    <div className="salesToDiv">to</div>
                    <span>$</span>
                    <div>
                      <input
                        id="maxSales-input"
                        min={this.props.minSalesFilterVal + 1}
                        max="50"
                        className="salesInput"
                        value={this.props.maxSalesFilterVal}
                        type="number"
                        onChange={(e) => { this.setMaxSales(e) }}
                      />
                    </div>
                    <span>MM</span>
                  </div>
                </div>
                : null
              }
            </div>
            <div className="geocodeDiv flex">
              <button className="geocodeBtn" onClick={this.backToRadius}>Back</button>
              {this.state.reportButtonActive ?
                <div className="pushLeft">
                  {this.state.loadingReportText ?
                    <div className="print-loading-div">
                      <div className="print-loader"></div><span style={{ paddingLeft: '2px' }}>Downloading</span>
                    </div>
                    :
                    <button className="geocodeBtn" onClick={this.getReport}>Get Report</button>
                  }
                </div>
                : null
              }
              {this.props.printing ?
                <div className="print-loading-div">
                  <div className="print-loader"></div><span style={{ paddingLeft: '2px' }}>Printing</span>
                </div>
                :
                <button className="geocodeBtn" onClick={this.print}>Print Map</button>
              }
            </div>
            {this.state.showErrorMessage || this.props.printError ?
              <div className="errorText">
                {this.state.errorMessageText}
              </div>
              : null}
          </div>
        </div>
      </div >
    )
  }
}

export default Filters;