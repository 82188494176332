import React, { Component } from 'react';

export default class ReportTitleComponent extends Component {
  render() {
    const titleDescription = this.props.titleDescription || "Title Description"
    const whitePaperUrl = this.props.whitePaperUrl || "#";
    const buttonText = this.props.buttonText || 'View Document';

    return (
      <div className="summaryTxt">
        <div>
          {titleDescription}
        </div>
        {buttonText === 'none' ?
          null :
          <div className="buttonDiv">
            <span className="linkButton">
              <a className="methodologyLink" href={whitePaperUrl} target="_blank" rel="noopener noreferrer">
                {buttonText}
              </a>
            </span>
          </div>
        }
      </div>
    );
  }
}