import React from 'react';

function GlobeIcon() {
    return (
        <svg id="globeIcon" xmlns="http://www.w3.org/2000/svg" height="6px" viewBox="0 0 92.15 92.15">
            <path id="Path_4355" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65,54.74A44.84,44.84,0,1,1,39.76,43.12" transform="translate(36.42 36.16)" />
            <path id="Path_4356" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M10.22-34.91c12.11,0,21.92,20.07,21.92,44.82S22.33,54.74,10.22,54.74" transform="translate(36.42 36.16)" />
            <path id="Path_4357" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.19-34.91c-12.1,0-21.92,20.07-21.92,44.82S-2.91,54.74,9.19,54.74" transform="translate(36.42 36.16)" />
            <path id="Path_4358" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M9.65-36.06v92" transform="translate(36.42 36.16)" />
            <path id="Path_4359" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M54.44,11.72H-35.17" transform="translate(36.42 36.16)" />
            <path id="Path_4360" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M50.74-8.21H-31.44" transform="translate(36.42 36.16)" />
            <path id="Path_4361" stroke="#0c9ed9" fill="none" strokeWidth="4" d="M49,31.64H-29.69" transform="translate(36.42 36.16)" />
        </svg>
    );
}

export default GlobeIcon;