//import React from '../../../../node_modules/react';
import React from 'react';
import '../../../App.css';
//import axios from '../../../../node_modules/axios';
import axios from 'axios';
import TextField from '../../../../node_modules/@material-ui/core/TextField';
import stateData from '../../../Assets/states.json';

const titleH2 = {
  margin: '-15px -20px',
  padding: '15px 20px 15px 20px',
  fontSize: '1.2em',
  lineHeight: '1.1',
  letterSpacing: '-.02em'
}

const textAreaDiv = {
  paddingTop: '15px',
  marginBottom: '14px'
}

const iconSpan = {
  paddingRight: '10px'
}

const ulStyle = {
  listStyleType: 'none',
  margin: 0,
  padding: '6px 0px 0px 0px',
  overflow: 'auto',
  maxHeight: '176px'
}

const filterDiv = {
  display: 'flex',
  flexDirection: 'row',
  padding: '5px 0px',
  alignItems: 'center'
}

const filterDivCol2 = {
  width: '60%',
}

const paddingTop = {
  paddingTop: '15px'
}

const textAreaDivSmall = {
  paddingTop: '10px',
  marginBottom: '5px',
  fontSize: '14px'
}

const apiToken = localStorage.getItem('access_token');

const headers = {
  'Authorization': 'Bearer ' + apiToken
};

class StndGeography extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      geogValue: 'zip',
      showSearchPanel: false,
      searchVal: '',
      suggestData: [],
      states: stateData.states,
      zips: [],
      cities: [],
      dmas: [],
      showErrorMessage: false,
      geogFilterVal: '',
      showFilterBtn: false,
      addToAggregate: false,
      aggregateQueryString: ''
    }

    this.geogRef = React.createRef();

    this.geogChange = this.geogChange.bind(this);
    this.goToTypeAhead = this.goToTypeAhead.bind(this);
    this.queryData = this.queryData.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.getStndGeoPoly = this.getStndGeoPoly.bind(this);
    this.clearAggregate = this.clearAggregate.bind(this);
    this.addToAggregate = this.addToAggregate.bind(this);
    this.geoRemove = this.geoRemove.bind(this);
  }

  geogChange(e) {
    this.setState({
      showSearchPanel: false,
      showFilterBtn: false,
      addToAggregate: false,
      aggregateQueryString: '',
      showErrorMessage: false
    });

    this.props.updatePolyArray([]);

    if (this.state[e.target.value].length > 0) {
      this.goToTypeAhead(e.target.value);
    } else {
      this.queryData(e.target.value);
    }
  }

  goToTypeAhead(value) {
    this.setState({
      showSearchPanel: true,
      suggestData: [],
      searchVal: '',
      geogValue: value,
      showErrorMessage: false,
      showFilterBtn: false
    });
  }

  queryData(value) {
    if (value === 'states') {
      this.goToTypeAhead(value);
    } else {
      axios.get(process.env.REACT_APP_APIURL + '/' + value, { headers: headers }).then(
        result => {
          if (result && result.data)
            this.setState({
              [value]: result.data,
              showSearchPanel: true,
              showErrorMessage: false,
              suggestData: [],
              searchVal: ''
            });

          this.goToTypeAhead(value);
        }
      ).catch(error => {
        if (error.response.status === 400 || error.response.status === 404) {
          this.setState({
            showErrorMessage: true,
          });
        }
      })
    }
  }

  getSuggestions(e) {
    this.setState({
      searchVal: e.target.value,
      showFilterBtn: false,
      showErrorMessage: false
    });

    if (e.target.value.length > 2) {
      let dataArray = [];
      let index = 1;
      if (this.state.geogValue === 'dmas') {
        for (const item of this.state.dmas) {
          if (item.DMA_MARKET.toLowerCase().indexOf(e.target.value.toLowerCase()) === 0) {
            dataArray.push({
              id: item.DMA_NO + "_" + index + "_dma",
              name: item.DMA_MARKET,
              state: ''
            });

            this.setState({
              suggestData: dataArray
            });

            index++;
          }
        }
      } else if (this.state.geogValue === 'cities') {
        for (const item of this.state.cities) {
          if (item.City.toLowerCase().indexOf(e.target.value.toLowerCase()) === 0) {
            dataArray.push({
              id: item.City + item.State + "_" + index + "_city",
              name: item.City,
              state: item.State
            });

            this.setState({
              suggestData: dataArray
            });

            index++;
          }
        }
      } else if (this.state.geogValue === 'zips') {
        for (const item of this.state.zips) {
          if (item.ZIP.toLowerCase().indexOf(e.target.value.toLowerCase()) === 0) {
            dataArray.push({
              id: item.ZIP + "_" + index + "_zip",
              name: item.ZIP,
              state: ''
            });

            this.setState({
              suggestData: dataArray
            });

            index++;
          }
        }
      } else if (this.state.geogValue === 'states') {
        for (const item of this.state.states) {
          if (item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) === 0) {
            dataArray.push({
              id: item.abbreviation + "_" + index + "_state",
              name: item.name,
              state: ''
            });

            this.setState({
              suggestData: dataArray
            });

            index++;
          }
        }
      }

    } else {
      this.setState({
        suggestData: []
      });
    }
  }

  setSearchValue(e, suggestion) {
    this.setState({
      searchVal: suggestion.name,
      suggestData: [],
    });

    const stndGeoType = this.getStndGeoType(suggestion.id);
    const idFirst = this.getStndGeoId(suggestion.id);
    let queryString;
    let geogString;
    let geoNameString;
    let aggQueryString;

    let startAggQS = '';

    if (this.state.addToAggregate) {
      startAggQS = this.state.aggregateQueryString;
    }

    if (stndGeoType === 'dma') {
      aggQueryString = startAggQS + idFirst + "|";
      queryString = stndGeoType + '=' + aggQueryString.slice(0, -1);
      geogString = 'generalizationLevel=3&geographyLayers=["US.DMA"]&geographyIDs=["' + idFirst + '"]';
      geoNameString = 'DMA: ' + suggestion.name;
    } else if (stndGeoType === 'state') {
      aggQueryString = startAggQS + idFirst + "|";
      queryString = stndGeoType + '=' + aggQueryString.slice(0, -1);
      geogString = 'generalizationLevel=4&geographyLayers=["US.States"]&geographyQuery=' + suggestion.name;
      geoNameString = 'State: ' + suggestion.name;
    } else if (stndGeoType === 'city') {
      aggQueryString = startAggQS + suggestion.name + suggestion.state + "|";
      queryString = 'citystate=' + aggQueryString.slice(0, -1);
      geogString = 'generalizationLevel=1&geographyLayers=["US.Places"]&geographyQuery=Name:"' + suggestion.name + '" AND MajorSubdivisionAbbr:' + suggestion.state;
      geoNameString = 'City: ' + suggestion.name + ", " + suggestion.state;
    } else if (stndGeoType === 'zip') {
      aggQueryString = startAggQS + suggestion.name + "|";
      queryString = stndGeoType + '=' + aggQueryString.slice(0, -1);
      geogString = 'generalizationLevel=0&geographyLayers=["US.ZIP5"]&geographyIDs=["' + suggestion.name + '"]';
      geoNameString = "ZIP: " + suggestion.name;
    } else {
      console.log('error in set search value');
    }

    this.setState({
      geogFilterVal: queryString,
      aggregateQueryString: aggQueryString
    });

    this.getStndGeoPoly(geogString, geoNameString, idFirst);
  }

  async getStndGeoPoly(geogString, geoNameString, idFirst) {
    let token = '';
    await axios.get(process.env.REACT_APP_APIURL + '/getOauth2token', { headers: headers })
      .then((result) => {
        token = result.data;
      });

    await axios.get(process.env.REACT_APP_GEOENRICHURL + '?sourceCountry=US&returnGeometry=true&' + geogString + '&f=pjson&token=' + token)
      .then((result) => {
        if (!result.data.results) {
          this.setState({
            showErrorMessage: true
          });
        } else {
          const ringsArray = result.data.results[0].value.features[0].geometry.rings;

          let newPolyArray = [];
  
          if (this.state.addToAggregate) {
            newPolyArray = Array.from(this.props.polys);
          }
  
          ringsArray.forEach(ring => {
            const reverseArray = ring.map(innerArray => innerArray.reverse());
            newPolyArray.push({ id: idFirst || 'id', name: geoNameString, position: reverseArray });
          });
          this.props.updatePolyArray(newPolyArray);
  
          this.setState({
            showFilterBtn: true
          });
        }
      });
  }

  getStndGeoType(string) {
    const substringsArray = string.split("_");
    const type = substringsArray[2];

    return type;
  }

  getStndGeoId(string) {
    const substringsArray = string.split("_");
    const idFirst = substringsArray[0];

    return idFirst;
  }

  addToAggregate() {
    this.setState({
      addToAggregate: true
    });
  }

  clearAggregate() {
    this.props.updatePolyArray([]);
    this.setState({
      addToAggregate: false,
      aggregateQueryString: '',
      showSearchPanel: true,
      suggestData: [],
      searchVal: '',
      showErrorMessage: false,
      showFilterBtn: false
    });
  }

  geoRemove(name) {
    const newArray = Array.from(this.props.polys).filter(item => item.name !== name);
    this.props.updatePolyArray(newArray);

    this._removeGeoFromAggString(name);
  }

  _removeGeoFromAggString(name) {
    const geogName = name.split(':')[0];
    const subStringName = name.split(':')[1];
    const subStringNoSpace = subStringName.trim();
    const aggQSClone = this.state.aggregateQueryString.slice(0, -1);
    let newAggQS = '';

    if (aggQSClone.indexOf(subStringNoSpace + '|') !== -1) {
      newAggQS = aggQSClone.replace(subStringNoSpace + '|', '');
    } else if (aggQSClone.indexOf('|' + subStringNoSpace) !== -1) {
      newAggQS = aggQSClone.replace('|' + subStringNoSpace, '');
    }

    this.setState({
      geogFilterVal: geogName.toLowerCase() + "=" + newAggQS,
      aggregateQueryString: newAggQS + "|"
    });
  }

  _setReportName() {
    let reportNameGeog = this.state.geogFilterVal.split('=')[0];
    if (reportNameGeog === 'citystate') {
      reportNameGeog = 'city';
    }
    const reportNameCapitalized = reportNameGeog.charAt(0).toUpperCase() + reportNameGeog.slice(1);

    const reportNameAgg = this.state.geogFilterVal.split('=')[1];
    let reportNameAggComma = '';

    if (reportNameGeog === 'dma') {
      const polyArrayClone = Array.from(this.props.polys).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      polyArrayClone.forEach(poly => {
        const formattedName = poly.name.split(': ')[1];
        reportNameAggComma = reportNameAggComma + formattedName + ', ';
      });
      reportNameAggComma = reportNameAggComma.trim().slice(0, -1);
    } else if (reportNameGeog === 'city') {
      const polyArrayClone = Array.from(this.props.polys).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      polyArrayClone.forEach(poly => {
        const formattedName = poly.name.split(': ')[1];
        reportNameAggComma = reportNameAggComma + formattedName.replace(',', '') + ', ';
      });
      reportNameAggComma = reportNameAggComma.trim().slice(0, -1);
    } else {
      reportNameAggComma = reportNameAgg.replace('|', ', ');
    }

    const title = reportNameCapitalized + ": " + reportNameAggComma;

    return title;
  }

  setFilters() {
    this.props.updateGeogQuery(this.state.geogFilterVal);
    this.props.updateReportName(this._setReportName());
    this.props.updateReportAddress(this._setReportName());
    this.props.showFilters();
    this.props.setMethodType('none');
  }

  componentWillUnmount() {
    this.setState({
      addToAggregate: false
    });
  }

  render() {
    const polyArrayClone = Array.from(this.props.polys).filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);

    return (
      <div>
        <h2 style={titleH2}>
          <span style={iconSpan}>
            <svg id="geogIcon1" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 90.38 86.3">
              <path id="Path_4389" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M-22.63-.24l.06-2.5,4.33-5,4.44.07L-.71-10.29-.77-7.86-2.85-5.23,1.27-.11l-8.6,10.36-.06,4.87,8.6,2.5L5.7,22.73,3.51,28.29,1.42,30.35-.72,43.15l-4.6-2.56L-7.19,28l-4.49-5.24,2.14-5.06L-16,10.06-18.35-.12Z" transform="translate(32.71 31.41)" />
              <path id="Path_4390" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M4.57-10.4l5.35,0V-7S4.71-3.7,4.66-3.82,2.74-7,2.74-7Z" transform="translate(32.71 31.41)" />
              <path id="Path_4391" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M14.14-.47l-2.4,7.16L20.61,2.1V4.87l-8.87,7.3-1.92,8,6.41,5.15,1.92,12.45L22.7,43.1l6.62-15.27-.05-5.47-4.22-6.92-4.87-3,4.65-.25,4.49,4.34,4.33-2.89,3.26,5.09.77-2.55q1.09-1.95,2.38-3.8c.22.26,4.49,5,4.49,5l1.53-5.86,1,.45-.37-2.81.64-2.47,3.58-4.52-2-2.65,2.31-1.62.85,1.55,1-2.85,3.3-2.3L50-6.7,36-8.27,20.4-5.5Z" transform="translate(32.71 31.41)" />
              <path id="Path_4392" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M45.91,22.37s1.51-2,1.48-2.05Z" transform="translate(32.71 31.41)" />
              <path id="Path_4393" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M49.54,16.13a4.78,4.78,0,0,0,1-.34Z" transform="translate(32.71 31.41)" />
              <path id="Path_4394" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeMiterlimit="10" d="M52.32,22.22" transform="translate(32.71 31.41)" />
              <path id="Path_4395" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M42.76,24l-.65,1.5s-1.49-1.81-1.52-2Z" transform="translate(32.71 31.41)" />
              <path id="Path_4396" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M42.36,32.3l8.71-4.43,2.35,6.68L51,41.07,47,36.63l-4.91.06Z" transform="translate(32.71 31.41)" />
              <path id="Path_4398" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M54.33,42.28l1.25-1.73Z" transform="translate(32.71 31.41)" />
              <path id="Path_4399" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M9.16,3.76l1-3.37Z" transform="translate(32.71 31.41)" />
              <path id="Path_4400" fill="none" stroke="#0c9ed9" strokeWidth="2" strokeLinejoin="round" d="M16.61-8.38,15.26-7Z" transform="translate(32.71 31.41)" />
              <path id="Path_4411" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-10.69,51.54a44.21,44.21,0,0,1,40.43-78.3" transform="translate(32.71 31.41)" />
              <path id="Path_4412" fill="none" stroke="#0c9ed9" strokeWidth="4" d="M-10.85,44.69l1.34,7.63-7.63,1.34" transform="translate(32.71 31.41)" />
            </svg>
          </span>
          Standard Geography</h2>
        <div style={textAreaDiv}>
          <div style={filterDiv}>
            <div className="geogTypeLabel">Geography Type:</div>
            <div style={filterDivCol2}>
              <select
                id="geog-select"
                className="filterSelect"
                onChange={(e) => { this.geogChange(e) }}
                defaultValue={"none"}
                ref={this.geogRef}
              >
                <option value="none" hidden>Choose a geography</option>
                <option value="dmas">DMA</option>
                <option value="states">State</option>
                <option value="cities">City</option>
                <option value="zips">ZIP</option>
              </select>
            </div>
          </div>
          {
            this.state.showSearchPanel ?
              <div>
                <div style={paddingTop}>
                  <TextField
                    id="stndGeog-input"
                    label="Start typing to search"
                    variant="outlined"
                    value={this.state.searchVal}
                    size="small"
                    fullWidth
                    onChange={(e) => { this.getSuggestions(e) }}
                  />
                </div>
                {this.state.suggestData ?
                  <ul style={ulStyle}>
                    {this.state.suggestData.map(suggestion => (
                      <li
                        className="search"
                        id={suggestion.id}
                        key={suggestion.id + "_" + suggestion.name}
                        value={suggestion.name + "_" + suggestion.state}
                        onClick={(e) => { this.setSearchValue(e, suggestion) }}
                      >
                        {suggestion.name} {suggestion.state}
                      </li>
                    ))}
                  </ul>
                  : null}
              </div>
              : null
          }

          {this.state.showFilterBtn && this.props.polys.length > 0 ?
            <div className="geocodeDiv flexRight stndGeo">
              <div className="paddingR10"><button className="geocodeBtn" onClick={this.addToAggregate}>Add to Aggregate</button></div>
              <div className="paddingR10"><button className="geocodeBtn" onClick={this.clearAggregate}>Clear Aggregate</button></div>
              <button className="geocodeBtn" onClick={this.setFilters}>Set Filters</button>
            </div>
            : null
          }
          {
            this.props.polys.length > 0 && this.state.addToAggregate ?
              <div>
                <div style={textAreaDivSmall}>Aggregated Geographies</div>
                <div className="aggGeoDiv">
                  {polyArrayClone.map((poly, i) =>
                    <div className="aggGeoRow" key={i + "_poly"}><span>{poly.name}</span>
                      <span className="geoRemove" onClick={this.geoRemove.bind(this, poly.name)}>remove</span>
                    </div>
                  )}
                </div>
              </div>
              : null
          }
          {this.state.showErrorMessage ?
            <div className="errorText">
              Oops!  Something went wrong.  Please try again.
          </div>
            : null}
        </div>
      </div>
    )
  }
}

export default StndGeography;