import React from 'react';
import Script from 'react-load-script';
import { Map, Marker, Circle, ZoomControl, FeatureGroup, Polygon } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import '../../utils/esri-leaflet-vector/mapbox-gl.css';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import PopupContent from './PopupContent/PopupContent';
import { EditControl } from "react-leaflet-draw"
import CustomMapBtns from './CustomMapBtns';
import '../../App.css';

const mapDiv = {
  height: '100%',
  zIndex: 100
}

const icon = L.divIcon({
  className: 'custom-icon',
  iconAnchor: [9, 24],
  html: ReactDOMServer.renderToString(<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 12.51 18.35"><path d="M8.79.79A5.73,5.73,0,0,0,3,6.51C3,9.66,8.79,18.36,8.79,18.36s5.75-8.7,5.75-11.85A5.73,5.73,0,0,0,8.79.79Z" transform="translate(-2.53 -0.29)" fill="#fff" /><path id="Path_4114" d="M8.75,7.39A1.42,1.42,0,1,0,7.32,6,1.43,1.43,0,0,0,8.75,7.39Z" transform="translate(-2.53 -0.29)" fill="none" stroke="#25408f" /><path id="Path_4115" d="M11,14.89s.83-1.36,1.67-3a15.92,15.92,0,0,0,1.91-5.35,4.77,4.77,0,0,0-.48-2.36A6.06,6.06,0,0,0,8.78.79,5.74,5.74,0,0,0,3,6.51C3,9.66,8.78,18.36,8.78,18.36" transform="translate(-2.53 -0.29)" fill="none" stroke="#25408f" /></svg>),
});

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

let rbDynamicLayer;

class MapComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSegmentFilterVal: this.props.segmentFilterVal,
      prevSubsegmentFilterVal: this.props.subsegmentFilterVal,
      prevCategoryFilterVal: this.props.categoryFilterVal,
      prevChainFilterVal: this.props.chainFilterVal,
      prevMaxSalesFilterVal: this.props.maxSalesFilterVal,
      prevMinSalesFilterVal: this.props.minSalesFilterVal,
      prevMapCenter: this.props.mapCenter,
      prevMapZoom: this.props.mapZoom,
      prevPoly: this.props.polys,
      esriLeafletLoaded: false,
      esriVectorLoaded: false,
      mapBoxGLLoaded: false,
      basemap: 'colliers-grey'
    }

    this.getIcon = this.getIcon.bind(this);
    this.addMapClickPoint = this.addMapClickPoint.bind(this);
    this.updateMapPoints = this.updateMapPoints.bind(this);
    this._onDrawStart = this._onDrawStart.bind(this);
    this.removeAllEditControlLayers = this.removeAllEditControlLayers.bind(this);
    this._onDeleted = this._onDeleted.bind(this);
    this.handleZoomEnd = this.handleZoomEnd.bind(this);
    this._onDrawMounted = this._onDrawMounted.bind(this);
    this._onCreated = this._onCreated.bind(this);
    this._onEditStop = this._onEditStop.bind(this);
    this._onEditStart = this._onEditStart.bind(this);
    this.updateMapState = this.updateMapState.bind(this);
    this.updateBasemap = this.updateBasemap.bind(this);
  }

  componentDidUpdate() {
    let updateObj = {};
    let updateState = false;

    if (this.state.prevPoly !== this.props.polys) {

      if (this.props.polys[0]) {
        let latArray = [];
        let lngArray = [];
        this.props.polys.forEach(poly => {
          if (poly.name !== 'Custom Polygon') {
            //get min max lat/lng to fit bounds of stnd geo poly to map
            poly.position.forEach(innerArray => {
              latArray.push(innerArray[0]);
              lngArray.push(innerArray[1]);
            });
          }
        });

        if (latArray.length > 0) {
          const north = Math.max(...latArray);
          const south = Math.min(...latArray);
          const east = Math.max(...lngArray);
          const west = Math.min(...lngArray);

          const map1 = this.refs.map.leafletElement;
          map1.fitBounds([
            [south, west],
            [north, east]
          ]);
        }

        updateObj = {
          prevPoly: this.props.polys,
        }

        updateState = true;
      }
    }

    let segmentArrayEqual = this.state.prevSegmentFilterVal.length === this.props.segmentFilterVal.length && this.state.prevSegmentFilterVal.every(item => this.props.segmentFilterVal.indexOf(item) > -1);
    let subsegmentArrayEqual = this.state.prevSubsegmentFilterVal.length === this.props.subsegmentFilterVal.length && this.state.prevSubsegmentFilterVal.every(item => this.props.subsegmentFilterVal.indexOf(item) > -1);
    let categoryArrayEqual = this.state.prevCategoryFilterVal.length === this.props.categoryFilterVal.length && this.state.prevCategoryFilterVal.every(item => this.props.categoryFilterVal.indexOf(item) > -1);
    let chainArrayEqual = this.state.prevChainFilterVal.length === this.props.chainFilterVal.length && this.state.prevChainFilterVal.every(item => this.props.chainFilterVal.indexOf(item) > -1);

    if (segmentArrayEqual === false || subsegmentArrayEqual === false || categoryArrayEqual === false || chainArrayEqual === false
      || this.state.prevMaxSalesFilterVal !== this.props.maxSalesFilterVal || this.state.prevMinSalesFilterVal !== this.props.minSalesFilterVal) {
      updateObj = {
        prevSegmentFilterVal: this.props.segmentFilterVal,
        prevSubsegmentFilterVal: this.props.subsegmentFilterVal,
        prevCategoryFilterVal: this.props.categoryFilterVal,
        prevChainFilterVal: this.props.chainFilterVal,
        prevMinSalesFilterVal: this.props.minSalesFilterVal,
        prevMaxSalesFilterVal: this.props.maxSalesFilterVal
      };

      this.updateMapPoints();
      updateState = true;
    } else if (this.state.prevMapZoom !== this.props.mapZoom || this.state.prevMapCenter !== this.props.mapCenter) {
      updateObj = {
        prevMapCenter: this.props.mapCenter,
        prevMapZoom: this.props.mapZoom,
      }

      updateState = true;
    }

    if (updateState) {
      this.setState(updateObj)
    }

    return null;
  }

  getIcon(rpoint) {
    console.log(rpoint);
    let height = '5px';
    let sales = parseFloat(rpoint.Sales.replace(/\$|,/g, ''));
    if (sales > 1000000 && sales < 10000000) {
      const scale = (sales - 1000000) / 360000;
      const roundedScale = Math.round(scale) + 5;
      height = roundedScale + 'px';
    } else if (sales >= 10000000) {
      height = '30px';
    }

    let fill = '#0c9ed9';

    if (rpoint.Segment === 'QSR') {
      fill = '#25408f';
    }

    return L.divIcon({
      className: 'custom-icon',
      iconAnchor: [height / 2, height / 2],
      html: ReactDOMServer.renderToString(<svg id="circleIcon" xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 20 20">
        <circle fill={fill} stroke="#ffffff" cx="10" cy="10" r="8" />
      </svg>),
    });
  }

  setPopupContent(self, rpoint) {
    self.setContent(<div>{rpoint.id}</div>)
  }

  addMapClickPoint(e) {
    const self = this;
    if (this.props.mapAddPt) {
      const newPointsArray = [
        {
          position: {
            lat: e.latlng.lat,
            lng: e.latlng.lng
          },
          content: {
            id: '1234',
          }
        }];
      this.props.updatePointsArray(newPointsArray);
      this.props.updateMapCenter(newPointsArray[0].position);
    } else {
      setTimeout(() => {
        if (document.getElementById('popUsePointBtn')) {
          L.DomEvent.on(
            document.getElementById('popUsePointBtn'), 'click', function (e) {
              self.props.updateMapRestPoint(false);
              const pointInfo = JSON.parse(e.target.value);
              const coordinates = pointInfo.position;
              const newPtArray = [{
                position: {
                  lat: parseFloat(coordinates[1]),
                  lng: parseFloat(coordinates[0])
                },
                content: {
                  id: pointInfo.id
                }
              }];

              self.props.updatePointsArray(newPtArray);
              self.props.updateMapCenter(newPtArray[0].position);
              self.props.updateMapRestPoint(true);
            }
          );
        }
      }, 1000);
    }
  }

  updateMapPoints() {
    let segmentVals = "";
    let subsegmentVals = "";
    let subsegmentAND = "";
    let categoryVals = "";
    let categoryAND = "";
    let chainVals = "";
    let chainAND = "";
    let salesVals = "";
    let salesAND = "";

    if (this.props.segmentFilterVal.length > 0) {
      const segmentString = this.props.segmentFilterVal.map(function (item) { return "'" + item + "'"; }).join(", ");
      segmentVals = 'segment IN(' + segmentString + ')';
    }

    if (this.props.subsegmentFilterVal.length > 0) {
      if (segmentVals !== "") {
        subsegmentAND = " AND ";
      }
      const subsegmentString = this.props.subsegmentFilterVal.map(function (item) { return "'" + item + "'"; }).join(", ");
      subsegmentVals = subsegmentAND + "subsegment IN(" + subsegmentString + ")";
    }

    if (this.props.categoryFilterVal.length > 0) {
      if (subsegmentVals !== "" || segmentVals !== "") {
        categoryAND = " AND ";
      }
      const categoryString = this.props.categoryFilterVal.map(function (item) { return "'" + item + "'"; }).join(", ");
      categoryVals = categoryAND + "category IN(" + categoryString + ")";
    }

    if (this.props.chainFilterVal.length > 0) {
      if (subsegmentVals !== "" || segmentVals !== "" || categoryVals !== "") {
        chainAND = " AND ";
      }
      const chainString = this.props.chainFilterVal.map(function (item) { return "'" + item.replace("'", "''") + "'"; }).join(",");
      chainVals = chainAND + "chain IN(" + chainString + ")";
    }

    if (this.props.minSalesFilterVal > 0 || this.props.maxSalesFilterVal < 50 * 1000) {
      if (subsegmentVals !== "" || segmentVals !== "" || categoryVals !== "" || chainVals !== "") {
        salesAND = " AND ";
      }
      salesVals = salesAND + "((" + process.env.REACT_APP_SALESCOL + " >= " + (this.props.minSalesFilterVal * 1000) + " AND " + process.env.REACT_APP_SALESCOL + " <=" + (this.props.maxSalesFilterVal * 1000) + ") OR " + process.env.REACT_APP_SALESCOL + " IS NULL)";
    }

    if (rbDynamicLayer) {
      rbDynamicLayer.setLayerDefs({ 0: segmentVals + subsegmentVals + categoryVals + chainVals + salesVals });
    }
  }

  handleZoomEnd(e) {
    const mapRef = this.refs.map.leafletElement;
    this._getMapBounds(mapRef);
    this._getMapCenter(mapRef);
    this.props.updateMapMoved(!this.props.mapMoved);

    //move map slightly to refresh
    if (e && e.type === "viewreset") {
      setTimeout(() => {
        const currentCenter = mapRef.getCenter();
        mapRef.setView([currentCenter.lat + 0.01, currentCenter.lng + 0.01], mapRef.getZoom());
        mapRef.setView([(currentCenter.lat + 0.01) - 0.01, (currentCenter.lng + 0.01) - 0.01], mapRef.getZoom());
      }, 500);
    }
  }

  _getMapBounds(mapRef) {
    const mapBounds = mapRef.getBounds();
    this.props.updateMapBounds(mapBounds);
  }

  _getMapCenter(mapRef) {
    const mapCenter = mapRef.getCenter();
    this.props.updateMapPrintCenter(mapCenter);
  }
  _onDrawMounted() {
    //console.log(this.props.editControlLayer);
    //this.refs.customPolyDraw.leafletElement.options.edit.featureGroup._layers = this.props.editControlLayer;
    setTimeout(() => {
      if (Object.keys(this.props.editControlLayer).length !== 0) {
        const layerContainer = this.refs.customPolyDraw.leafletElement.options.edit.featureGroup;
        const layer_ids = Object.keys(this.props.editControlLayer);

        layer_ids.forEach(id => {
          let layer = this.props.editControlLayer[id];
          layerContainer.addLayer(layer);
        });
      }
    }, 500);

  }

  _onDrawStart() {
    this.removeAllEditControlLayers();
    this.props.updatePolyArray([]);
  }

  _onCreated(e) {
    const layerContainer = this.refs.customPolyDraw.leafletElement.options.edit.featureGroup;
    this.props.updateEditControlLayer(layerContainer._layers);
    this.props.updatePolyArray([{ id: e.layerType, name: 'Custom Polygon', position: e.layer.getLatLngs() }]);
  }

  _onDeleted() {
    this.props.updatePolyArray([]);
    this.removeAllEditControlLayers();
    this.props.updateEditControlLayer({});
  }

  _onEditStart() {
    this.props.updatePolyArray([]);
  }

  _onEditStop() {
    const layerContainer = this.refs.customPolyDraw.leafletElement.options.edit.featureGroup;

    const layer_ids = Object.keys(layerContainer._layers);
    let coords;

    layer_ids.forEach(id => {
      let layer = layerContainer._layers[id];
      coords = layer.getLatLngs();
    });

    this.props.updatePolyArray([{ id: 'polygon', name: 'Custom Polygon', position: coords }]);
    this.props.updateEditControlLayer(layerContainer._layers);
  }

  removeAllEditControlLayers() {
    const layerContainer = this.refs.customPolyDraw.leafletElement.options.edit.featureGroup;
    const layers = layerContainer._layers;
    const layer_ids = Object.keys(layers);

    layer_ids.forEach(id => {
      let layer = layers[id]
      layerContainer.removeLayer(layer);
    });
  }

  updateMapState(updateObj) {
    this.setState(updateObj);
  }

  updateBasemap(value) {
    const map1 = this.refs.map.leafletElement;

    map1.eachLayer(function (layer) {
      if (layer._mapboxGL && layer._mapboxGL.options.accessToken === 'ezree')
        map1.removeLayer(layer);
      if (layer.options && layer.options.pane === 'tile') {
        map1.removeLayer(layer);
      }
    });

    if (value === 'hybrid') {
      map1.createPane('tile');
      map1.getPane('tile').style.zIndex = 150;
      L.esri.basemapLayer('ImageryFirefly', { pane: 'tile' }).addTo(map1);
      L.esri.Vector.layer('c116ff57c1b9474c9924b07fd45ae60d').addTo(map1);
    } else {
      L.esri.Vector.layer('855a4d90d0f34769aa5f9672ef65e5a3').addTo(map1);
    }

    var originalInitTile = L.GridLayer.prototype._initTile
    L.GridLayer.include({
      _initTile: function (tile) {
        originalInitTile.call(this, tile);

        var tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 1 + 'px';
        tile.style.height = tileSize.y + 1 + 'px';
      }
    });

    //move map slightly to refresh
    setTimeout(() => {
      const currentCenter = map1.getCenter();
      map1.setView([currentCenter.lat + 0.02, currentCenter.lng + 0.02], map1.getZoom());
      map1.setView([(currentCenter.lat + 0.02) - 0.02, (currentCenter.lng + 0.02) - 0.02], map1.getZoom());
    }, 500);

    this.setState({
      basemap: value
    });
  }

  onMapBoxGLLoaded() {
    this.setState({ mapBoxGLLoaded: true });
  }

  onEsriLeafletLoaded() {
    this.setState({ esriLeafletLoaded: true });
  }

  async onEsriVectorLoaded() {
    this.setState({ esriVectorLoaded: true });

    const map1 = this.refs.map.leafletElement;

    //detect edge and use tile layer instead of vector
    var browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1: return "MS Edge";
        case agent.indexOf("edg") > -1: return "MS Edge";
        default: return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    if (browser === "MS Edge") {
      map1.createPane('tile');
      map1.getPane('tile').style.zIndex = 150;
      L.esri.tiledMapLayer({ url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer', maxNativeZoom: 15, pane: 'tile' }).addTo(map1);
      L.esri.tiledMapLayer({ url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer', maxNativeZoom: 15 }).addTo(map1);
    } else {
      L.esri.Vector.layer('855a4d90d0f34769aa5f9672ef65e5a3').addTo(map1);
    }

    const token = localStorage.getItem('access_token');

    const headers = {
      'Authorization': 'Bearer ' + token
    };

    const self = this;

    axios.get(process.env.REACT_APP_APIURL + '/getAtlasToken', { headers: headers })
      .then(result => {
        rbDynamicLayer = L.esri.dynamicMapLayer({
          url: process.env.REACT_APP_DYNAMICMAPURL,
          opacity: 1,
          token: result.data.token,
          layerDefs: { 0: "segment IS NOT NULL" }
        });
        rbDynamicLayer.addTo(map1);

        rbDynamicLayer.bindPopup(function (error, featureCollection) {
          if (error || featureCollection.features.length === 0) {
            return false;
          } else {
            const sales = featureCollection.features[0].properties['Curr_SLS_0'];
            const formattedSales = () => {
              const salesFormatted = '$' + numberWithCommas(sales * 1000);
              return salesFormatted;
            }

            const stringComponent = ReactDOMServer.renderToString(
              <PopupContent
                coordinates={featureCollection.features[0].geometry.coordinates}
                content={featureCollection.features[0].properties}
                sales={formattedSales()}
                mapAddPt={self.props.mapAddPt}
              />
            );
            return stringComponent;
          }
        });
      }).catch(error => {
        console.log(error);
      });

    this._getMapBounds(map1);
  }

  render() {
    return (

      <div className="mapContainer" id="mapDiv">
        <Script
          url={process.env.PUBLIC_URL + 'basemap-plugin/mapbox-gl.js'}
          onLoad={this.onMapBoxGLLoaded.bind(this)}
        />
        {this.state.mapBoxGLLoaded ?
          <Script
            key="esriLeaflet"
            url={process.env.PUBLIC_URL + 'basemap-plugin/esri-leaflet_orig.js'}
            onLoad={this.onEsriLeafletLoaded.bind(this)}
          />
          : null}

        {this.state.esriLeafletLoaded ?
          <Script
            key="esriVector"
            url={process.env.PUBLIC_URL + 'basemap-plugin/esri-leaflet-vector_orig.js'}
            onLoad={this.onEsriVectorLoaded.bind(this)}
          />
          : null
        }
        {
          this.state.prevMapCenter.length > 0 ?
            <Map
              style={mapDiv}
              zoom={this.state.prevMapZoom}
              center={this.state.prevMapCenter}
              zoomControl={false}
              ref='map'
              onViewReset={(e) => { this.handleZoomEnd(e) }}
              onZoomEnd={this.handleZoomEnd}
              onMoveEnd={this.handleZoomEnd}
              onclick={(e) => { this.addMapClickPoint(e) }}
              key={"myMap"}
            >
              {this.props.points.length > 0 && this.props.driveTime === false ?
                <Circle
                  center={this.props.points[0].position}
                  fillColor="#0c9ed9"
                  radius={this.props.radiusVal * 1609}
                />
                : null
              }
              {this.props.points.map((point, idx) =>
                <Marker
                  key={point.content.id}
                  position={point.position}
                  icon={icon}
                >
                </Marker>
              )}
              {
                this.props.mapDrawPt ?
                  <FeatureGroup>
                    <EditControl
                      ref='customPolyDraw'
                      position='topleft'
                      onMounted={this._onDrawMounted}
                      onDrawStart={this._onDrawStart}
                      onCreated={(e) => { this._onCreated(e) }}
                      onDeleted={this._onDeleted}
                      onEditStop={this._onEditStop}
                      onEditStart={this._onEditStart}
                      draw={{
                        rectangle: {
                          showArea: false
                        },
                        polygon: {
                          showArea: false
                        },
                        polyline: false,
                        circle: false,
                        marker: false,
                        circlemarker: false
                      }}
                      fillColor="#0c9ed9"
                    />
                  </FeatureGroup>
                  : null
              }
              {this.props.polys.map((poly, i) =>
                <Polygon key={i + "_poly"} fillColor="#0c9ed9" positions={poly.position} />
              )}
              <ZoomControl position="topright" />
            </Map>
            : null
        }
        <CustomMapBtns
          basemap={this.state.basemap}
          updateBasemap={this.updateBasemap}
          updateMapState={this.updateMapState}
        />
      </div>

    );
  }
}

export default MapComponent;