import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default class HierarchyTable extends Component {
  createData(segment, subsegment, category) {
    return { segment, subsegment, category };
  }

  rows = [
    this.createData('QSR', 'QSR', 'Asian'),
    this.createData('', '', 'Chicken'),
    this.createData('', '', 'Donut'),
    this.createData('', '', 'Fish'),
    this.createData('', '', 'Hamburger/Roast Beef'),
    this.createData('', '', 'Mediterranean'),
    this.createData('', '', 'Mexican'),
    this.createData('', '', 'Pizza'),
    this.createData('', '', 'Salad'),
    this.createData('', '', 'Sub'),
    this.createData('FSR', 'Family', 'Coffee Shop'),
    this.createData('', '', 'Steak/BBQ'),
    this.createData('', 'Dining', 'Casual'),
    this.createData('', '', 'Italian'),
    this.createData('', '', 'Mexican'),
    this.createData('', '', 'Pizza'),
    this.createData('', '', 'Seafood'),
    this.createData('', '', 'Steak/Rib'),
  ];

  render() {
    return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <div style={{ maxWidth: '450px', alignSelf: 'center' }}>
          <TableContainer component={Paper}>
            <Table aria-label="hierarchy table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Segment</TableCell>
                  <TableCell>Subsegment</TableCell>
                  <TableCell>Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.rows.map((row, i) => (
                  <TableRow key={row.category + i}>
                    <TableCell component="th" scope="row">{row.segment}</TableCell>
                    <TableCell>{row.subsegment}</TableCell>
                    <TableCell>{row.category}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}