import React from 'react';

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.08 214.62" height="42">
      <path d="M1669.69-47.83H1336a21.71,21.71,0,0,0-21.68,21.7V145.15a21.7,21.7,0,0,0,20.37,21.63H1671a21.71,21.71,0,0,0,20.37-21.68V-26.13A21.71,21.71,0,0,0,1669.69-47.83Z" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <rect x="6" y="177.24" width="365.08" height="15.69" fill="#0c9ed9"/>
      <rect x="6" y="161.27" width="365.08" height="15.97" fill="#ffd400"/>
      <path d="M1335,160.79h335.61a15.69,15.69,0,0,0,14.74-15.64v-.05H1320.29v.05A15.68,15.68,0,0,0,1335,160.79Z" transform="translate(-1314.29 47.83)" fill="#ed1b34"/>
      <path d="M1669.69-41.83H1336a15.69,15.69,0,0,0-15.68,15.7V113.46h365.08V-26.13A15.69,15.69,0,0,0,1669.69-41.83Z" transform="translate(-1314.29 47.83)" fill="#25408f"/>
      <path d="M1455.64,49.26c0-10.35-5.46-16.38-9.8-16.38a7.34,7.34,0,0,0-5.91,2.57c-2.92,3.29-4,7.23-4,14.09,0,5.46.38,9,2.93,13.07a7.87,7.87,0,0,0,7.42,4.06c6.2,0,9.41-7.81,9.41-17.41m5-14.57c4.41,4.42,6.5,9.32,6.5,14.85a21.62,21.62,0,0,1-5.84,14.31,20.88,20.88,0,0,1-15.79,6.77c-5.94,0-11-1.71-15.25-6.77-4.13-4.9-5.08-8.85-5.08-14.59a21,21,0,0,1,7.9-15.61,20.35,20.35,0,0,1,13.35-4.52,19.94,19.94,0,0,1,14.21,5.56" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <path d="M1565.17,42.31c2,0,3.11-.57,3.11-1.32,0-2.82-.94-8.37-5.83-8.37-4.61,0-7.44,3.39-8.66,9.69Zm-11.66,6.3c0,9.6,6.87,15.24,12.23,15.24,5.83,0,9.39-2.46,11.38-5.46.38-.66.75-.66,1.14-.47l1.39.84c.48.28.67.66,0,1.88a18.62,18.62,0,0,1-17.11,10c-6,0-10.25-1.31-13.54-5.07-4.33-5-5.28-9.7-5.28-15.53,0-5.16.59-8.66,4.62-13.93,4-5.07,8.18-7,16.37-7,10.72,0,14.21,11.2,14.21,15.07,0,1.4-1,1.5-2.65,1.5h-22.76Z" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <path d="M1590.08,45.32c0-4.33-.74-5.83-3.57-7.33l-1.68-1c-.56-.19-.75-.37-.75-.76v-.56c0-.37.19-.56.75-.84l11.37-5.37a4.66,4.66,0,0,1,1.51-.38c.57,0,.75.58.85,1.15l.84,6.76h.38c3.2-5.27,7.15-8.47,11.29-8.47a4.6,4.6,0,0,1,4.88,4.71,5.45,5.45,0,0,1-5.07,5.08,7.26,7.26,0,0,1-3.21-.75,4.23,4.23,0,0,0-2.62-.76,4.92,4.92,0,0,0-4,2.73A10.73,10.73,0,0,0,1600,44.2V62.34c0,3,.76,4,2.64,4h4.15c.55,0,.75.19.75.65v2.18c0,.46-.2.66-.67.66-.65,0-4.79-.29-11.67-.29-6.57,0-10.24.29-11,.29-.47,0-.66-.2-.66-.66V67.06c0-.58.19-.77.83-.77h3.31c1.5,0,2.43-.74,2.43-2.54Z" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <path d="M1618.87,57.65c-.09-.38,0-.67.27-.77l1.79-.55c.38-.1.66-.1.85.28l3.1,5.27c1.7,2.83,3.49,5.06,8.1,5.06,3.67,0,6.67-2.23,6.67-5.73,0-3.76-2.44-5.47-9-7.34-6.4-1.87-11.49-5.07-11.49-11.76a11.45,11.45,0,0,1,4.81-9.87,15.56,15.56,0,0,1,9.77-3.11,18.27,18.27,0,0,1,9.13,2.08c1.41.74,1.69,1.13,1.78,1.67l1.24,6.42a1.33,1.33,0,0,1-.1,1l-1.79.84a.58.58,0,0,1-.75-.38l-4.53-5.07a7.6,7.6,0,0,0-5.91-2.9c-3.48,0-6.5,2.06-6.5,5.35,0,3.86,2.83,5,8.47,6.48,4.34,1.12,7,2.26,9.41,4.89,2.07,2.07,2.82,4.33,2.82,7.54,0,7.89-6.4,13.54-15.7,13.54-5,0-9.13-1.6-10.18-2.45a2.63,2.63,0,0,1-.74-1Z" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <path d="M1539.72,66.29H1536c-.94,0-1.32-.74-1.5-2.44a80,80,0,0,1-.2-8.11V45.6c0-7.81,0-13.83.2-15.32.09-.77-.2-1.15-.76-1.15a6.59,6.59,0,0,0-2.26.58c-1.61.64-10.91,3.94-12.23,4.5-.47.2-.66.39-.66.76v1c0,.36.1.65.94.84,4.33,1.14,4.9,3,4.9,5.83V56c0,2.63-.09,5.63-.19,8.46-.1,1.52-.57,1.88-1.5,1.88a35.84,35.84,0,0,1-4.66.14,50.7,50.7,0,0,1-5.08-.14c-2.06,0-2.24-1.32-2.44-3-.19-2.81-.19-12.4-.19-16.73V33c0-5.17.19-24.74.39-29.81,0-1.32-.39-1.69-1-1.69a8.12,8.12,0,0,0-2.06.56c-3,1.88-10.35,4.33-14.11,5.46-.58.18-.75.55-.75.74V9.37c0,.4,0,.58.56.78l1.88.55c3,1,4.61,2.45,4.79,5.65.19,2.44.38,10.53.38,17.11V62.91c0,2.64-1.42,3.38-2.35,3.38,0,0-1.41.14-4.86.14s-4.79-.14-4.79-.14c-2.08,0-2.28-1.32-2.46-3-.19-2.81-.19-12.4-.19-16.73V33c0-5.17.19-24.74.37-29.81,0-1.32-.37-1.69-.92-1.69a8.4,8.4,0,0,0-2.08.56c-3,1.88-10.34,4.33-14.1,5.46-.57.18-.76.55-.76.74V9.37c0,.4,0,.58.58.78l1.87.55c3,1,4.6,2.45,4.79,5.65.2,2.44.38,10.53.38,17.11V62.91c0,2.64-1.4,3.38-2.35,3.38H1470c-1.11,0-1.31.19-1.31.77v1.78c0,.56.2.94.75.94.38,0,18.41-.18,36.15-.18,17.11,0,33.94.18,34.31.18.57,0,.76-.38.76-.94v-1.9c0-.46-.19-.65-1-.65" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <path d="M1420.07,56c-.61-.58-1.7-1.55-1.78-1.64s-.43,0-.53.1c-4.18,5.3-12,9.89-21.66,9.89-4.52,0-10-.76-16.17-7.35-7-7.53-8.67-18.62-8.67-24.46,0-13.52,6.22-27.08,21.25-27.08,7.35,0,12.25,3,16.38,7a33.44,33.44,0,0,1,7.34,12.21c.28.76.56,1.14,1,1L1419,25c.38-.09.48-.39.38-.95-.38-2.82-2.26-17.86-2.26-19.54,0-1.33-.18-1.52-1.32-1.52s-1.32,0-1.49.58L1413.58,6c-.18.74-.56.74-1.88,0a41,41,0,0,0-19-4.52c-11.29,0-19.37,4.52-24.46,10a36.5,36.5,0,0,0-9.59,25.2c0,8.28,3.2,19.19,10.16,25.41,5.84,5.26,12.6,8.84,24.65,8.84,12.38,0,21-6.64,26.73-14a.78.78,0,0,0-.13-.89" transform="translate(-1314.29 47.83)" fill="#fff"/>
      <path d="M1533.94,17a5.74,5.74,0,1,1-5.74-5.74,5.74,5.74,0,0,1,5.74,5.74" transform="translate(-1314.29 47.83)" fill="#fff"/>
    </svg>
  );
}

export default Logo;