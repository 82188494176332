//import React from '../../../../node_modules/react';
import React from 'react';
import '../../../App.css';

const panelStyle = {
  minWidth: '200px',
  maxHeight: 'calc(100% - 100px)',
  backgroundColor: '#ffffff',
  bottom: 10,
  right: 10,
  zIndex: 900,
  position: 'fixed',
  overflow: 'auto',
  padding: '15px 20px',
  color: '#2e3133',
}

const titleH2 = {
  margin: '-15px -20px',
  padding: '15px 20px 15px 20px',
  fontSize: '1.2em',
  lineHeight: '1.1',
  letterSpacing: '-.02em'
}

const textAreaDiv = {
  paddingTop: '5px',
  marginBottom: '5px',
  fontSize: '12px'
}

const iconSpan = {
  paddingRight: '10px'
}

class Legend extends React.Component {
  constructor(props) {
    super(props);

    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.props.togglePanel('legend');
  }

  render() {
    return (
      <div style={panelStyle}>
        <div>
          <h2 style={titleH2}>
            <span style={iconSpan}>
              <svg id="legendIconSmall" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
                <path id="Path_4151" d="M55.9-26.44a9.46,9.46,0,0,0-9.46-9.46H-26.64a9.45,9.45,0,0,0-9.46,9.46V46.64a9.46,9.46,0,0,0,9.46,9.46H46.44a9.47,9.47,0,0,0,9.46-9.46h0V.15" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4152" d="M36-11.9h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4153" d="M36,12.1h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4154" d="M36,36.1h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4157" d="M-14.11,40.07a6,6,0,0,0,6-6,6,6,0,0,0-6-6,6,6,0,0,0-5.95,5.95,6,6,0,0,0,5.95,6Z" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4158" d="M-19.81-12.55l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
                <path id="Path_4159" d="M-19.81,10.45l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              </svg>
            </span>
            Restaurants</h2>
          <div style={textAreaDiv}>
            By Sales &amp; Segment
          </div>
          <div className="containerLgdDiv">
            <div className="segmentLgdDiv">
              <div className="legendTextLg">Segment</div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv2">
                  <svg height="10px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#0c9ed9" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">FSR</div>
              </div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv2">
                  <svg height="10px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#25408f" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">QSR</div>
              </div>
            </div>
            <div className="salesLgdDiv">
              <div className="legendTextLg">Sales</div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv">
                  <svg height="30px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#9EA2A2" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">$10MM +</div>
              </div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv">
                  <svg height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#9EA2A2" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">$8MM</div>
              </div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv">
                  <svg height="16px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#9EA2A2" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">$5MM</div>
              </div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv">
                  <svg height="11px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#9EA2A2" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">$3MM</div>
              </div>
              <div className="legendRowDiv">
                <div className="legendSVGDiv">
                  <svg height="5px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="8" fill="#9EA2A2" stroke="#ffffff" />
                  </svg>
                </div>
                <div className="legendTextMd">$1MM or less</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Legend;