import React, { Component } from 'react';
import GlobeIcon from '../GlobeIcon/GlobeIcon';

export default class ReportListComponent extends Component {
  render() {
    const reportTitle = this.props.reportTitle || "Title";
    const reportDescription = this.props.reportDescription;
    const handleClick = this.props.handleClick;

    return (
      <div className="summaryTxt rptList">
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '-2px 20px 0px 5px' }}>
            <GlobeIcon></GlobeIcon>
          </div>
          <div>
            <span onClick={handleClick} className="rptTitleTxt">{reportTitle}</span> {reportDescription}
          </div>
        </div>
        {/* <div className="buttonDiv marginL20">
          <span
            className="linkButton"
            onClick={handleClick}
          >
            View Sample Report
          </span>
        </div> */}
      </div>
    );
  }
}