//import React from '../../../../node_modules/react';
import React from 'react';
import '../../../App.css';
// import axios from '../../../../node_modules/axios';
import axios from 'axios';

const titleH2 = {
  margin: '-15px -20px',
  padding: '15px 20px 15px 20px',
  fontSize: '1.2em',
  lineHeight: '1.1',
  letterSpacing: '-.02em'
}

const textAreaDiv = {
  paddingTop: '15px',
  marginBottom: '14px'
}

const iconSpan = {
  paddingRight: '10px'
}

const matchAddressDiv = {
  fontSize: '0.8em',
  color: '#0c9ed9'
}

class ClickMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevMapPoint: [],
      matchAddress: '',
    }

    this.closePanel = this.closePanel.bind(this);
    this.usePoint = this.usePoint.bind(this);
    this.reverseGeocode = this.reverseGeocode.bind(this);
  }

  componentDidMount() {
    this.props.updateMapAddPt(true);
    document.getElementsByClassName('leaflet-container')[0].classList.add('mapClickCursor');
  }

  componentDidUpdate() {
    if (this.props.points.length > 0 && this.state.prevMapPoint.length === 0) {
      this.setState({
        prevMapPoint: this.props.points,
        matchAddress: ''
      });
      this.reverseGeocode(this.props.points);
    } else if (this.state.prevMapPoint.length > 0) {
      if (this.state.prevMapPoint[0].position.lat !== this.props.points[0].position.lat ||
        this.state.prevMapPoint[0].position.lng !== this.props.points[0].position.lng) {
        this.setState({
          prevMapPoint: this.props.points,
          matchAddress: ''
        });
        this.reverseGeocode(this.props.points);
      }
    }
  }

  closePanel() {
    this.props.togglePanel('click');
  }

  reverseGeocode(points) {
    axios.get(process.env.REACT_APP_GEOCODEURL + '/reverseGeocode?f=pjson&location=' + points[0].position.lng + "," + points[0].position.lat)
      .then(result => {
        if (result && result.data) {
          this.setState({
            matchAddress: result.data.address.Match_addr
          });
        } else {
          this.setState({
            matchAddress: points[0].position.lat + ", " + points[0].position.lng
          });
        }
      }).catch(error => {
        this.setState({
          matchAddress: points[0].position.lat + ", " + points[0].position.lng
        });
      });
  }

  usePoint() {
    this.props.showRadiusSection();
    this.props.setMethodType('none');
    this.props.updateReportName(this.state.matchAddress);
    this.props.updateReportAddress(this.state.matchAddress);
  }

  componentWillUnmount() {
    this.props.updateMapAddPt(false);
    document.getElementsByClassName('leaflet-container')[0].classList.remove('mapClickCursor');
  }

  render() {
    return (
      <div>
        <h2 style={titleH2}>
          <span style={iconSpan}>
            <svg id="starIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.63 94.63">
              <path id="Path_4164" d="M16.51,3.68c-7.1-20.22-5.4-19.73-12.33,0-21.68.43-20.6-1-3.82,11.63-6.27,20.5-7.32,19.1,10,7.18,17.77,12.23,16.1,12.8,10-7.18C37.61,2.35,37.56,4.1,16.51,3.68Z" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4165" d="M9.83-36A46.07,46.07,0,1,1-25-19.93" transform="translate(37.42 37.22)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
            </svg>
          </span>
          Click on Map</h2>
        <div style={textAreaDiv}>
          Click on the map to add a point or change point.
          </div>
        {
          this.props.points.length > 0 && this.state.matchAddress !== '' ?
            <div>
              <div>Address:</div>
              <div style={matchAddressDiv}>
                {this.state.matchAddress}
              </div>
              <div className="geocodeDiv flexRight">
                <button className="geocodeBtn" onClick={this.usePoint}>Use This Point</button>
              </div>
            </div>
            : null
        }
      </div>
    )
  }
}

export default ClickMap;