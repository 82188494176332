import React from 'react';

class RouteError extends React.Component {
  render() {
    return (
        <h1>Oops! Page not found!</h1>
    )
  }
}

export default RouteError;