import React from 'react';
import '../../App.css';

class OpeningBtns extends React.Component {
    constructor(props) {
        super(props);

        this.hideOpeningBtns = this.hideOpeningBtns.bind(this);
    }

    hideOpeningBtns(type) {
        this.props.togglePanel(type);
        this.props.updateOpeningBtns(false);
    }

    render() {
        return (
            <div>
                <section className="options-Panel">
                    <div>
                        <div className="options-Panel-bottom">
                            <div className="typeOptionBtn optionBtn" data-panel="type" data-study-area-type="address" onClick={this.hideOpeningBtns.bind(this, 'searchAddress')}>
                                <svg id="optSearchIcon" className="lnr" xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 85.9 94.5">
                                    <path id="Path_3886" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M35.7-36.09v16h16" transform="translate(32.96 37.34)" />
                                    <path id="Path_3887" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M-16.3-22.12V-32.86a3.2,3.2,0,0,1,3.15-3.23H35.7a2.33,2.33,0,0,1,1.8.94L50.75-22.07a2.58,2.58,0,0,1,1,2v72.8a3.2,3.2,0,0,1-3.17,3.2H-13.15a3.18,3.18,0,0,1-3.15-3.2V42.07" transform="translate(32.96 37.34)" />
                                    <path id="Path_3888" className="svg-hover-stroke" fill="none" strokeWidth="2.5" d="M3.6,27.41A19.74,19.74,0,1,0-16.13,7.67,19.74,19.74,0,0,0,3.6,27.41Z" transform="translate(32.96 37.34)" />
                                    <path id="Path_3889" className="svg-hover-stroke" fill="none" strokeWidth="2.5" d="M-10.34,21.62-32.07,43.35" transform="translate(32.96 37.34)" />
                                </svg>
                                <span>ADDRESS</span>
                            </div>
                            <div className="clickOptionBtn optionBtn" data-panel="click" data-study-area-type="point" onClick={this.hideOpeningBtns.bind(this, 'click')}>
                                <svg id="optClickIcon" className="lnr" xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 94.5 93.99">
                                    <path id="Path_4109" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M52.11,29l3.88.73V-31.65L31.45-35.78,7.76-24.86l-20-9.21L-36-25V36.31l23.8-9L2.13,33.75" transform="translate(37.26 37.08)" />
                                    <path id="Path_4110" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M-12.21-22.44V16" transform="translate(37.26 37.08)" />
                                    <path id="Path_4111" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M7.76-14.76V-.09" transform="translate(37.26 37.08)" />
                                    <path id="Path_4112" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M31.88-25.17V-5.74" transform="translate(37.26 37.08)" />
                                    <path id="Path_4114" className="svg-hover-stroke" fill="none" strokeWidth="2.5" d="M25.86,24.24a4.12,4.12,0,1,0-4.15-4.12,4.12,4.12,0,0,0,4.15,4.12Z" transform="translate(37.26 37.08)" />
                                    <path id="Path_4115" className="svg-hover-stroke" fill="none" strokeWidth="2.5" d="M26,56.22S9.21,30.88,9.21,21.67A16.71,16.71,0,0,1,26,5c8.75,0,17.27,7.8,16.76,16.67-.25,4.34-2.94,10.4-5.57,15.6-2.47,4.89-4.88,8.84-4.88,8.84" transform="translate(37.26 37.08)" />
                                </svg>
                                <span>PIN</span>
                            </div>
                            <div className="drawOptionBtn optionBtn" data-panel="draw" data-study-area-type="polygon" onClick={this.hideOpeningBtns.bind(this, 'draw')}>
                                <svg id="optDrawIcon" className="lnr" xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 88.62 94.1">
                                    <line id="Line_20" className="svg-hover-stroke" fill="none" strokeWidth="2.5" y1="92.85" x2="88.62" y2="92.85" />
                                    <path id="Path_4362" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M24.45-22.9,7.23-35A4.68,4.68,0,0,0,.72-33.89a4.48,4.48,0,0,0-.58,1.13L-3.51-22.3-30.89,5.46a4.65,4.65,0,0,0-1.34,3.23V56.11" transform="translate(34.31 37.14)" />
                                    <path id="Path_4363" fill="none" stroke="#ffffff" strokeWidth="2.5" d="M-28.37,55.58,13.52,40.67a4.6,4.6,0,0,0,2.57-2.37L32.84,3l8.58-7a4.68,4.68,0,0,0,.66-6.59,4.62,4.62,0,0,0-.92-.85" transform="translate(34.31 37.14)" />
                                    <circle id="Ellipse_2" fill="none" stroke="#ffffff" strokeWidth="2.5" cx="33.22" cy="52.86" r="5.67" />
                                    <line id="Line_21" fill="none" stroke="#ffffff" strokeWidth="2.5" x1="3.37" y1="92.08" x2="30.5" y2="56.12" />
                                </svg>
                                <span>DRAW POLYGON</span>
                            </div>
                            <div className="stdGeoOptionBtn optionBtn optionBtnAlt" data-panel="stdgeo" onClick={this.hideOpeningBtns.bind(this, 'geog')}>
                                <svg id="optGeogIcon" className="lnr" xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 90.38 86.3">
                                    <path id="Path_4389" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M-22.63-.24l.06-2.5,4.33-5,4.44.07L-.71-10.29-.77-7.86-2.85-5.23,1.27-.11l-8.6,10.36-.06,4.87,8.6,2.5L5.7,22.73,3.51,28.29,1.42,30.35-.72,43.15l-4.6-2.56L-7.19,28l-4.49-5.24,2.14-5.06L-16,10.06-18.35-.12Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4390" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M4.57-10.4l5.35,0V-7S4.71-3.7,4.66-3.82,2.74-7,2.74-7Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4391" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M14.14-.47l-2.4,7.16L20.61,2.1V4.87l-8.87,7.3-1.92,8,6.41,5.15,1.92,12.45L22.7,43.1l6.62-15.27-.05-5.47-4.22-6.92-4.87-3,4.65-.25,4.49,4.34,4.33-2.89,3.26,5.09.77-2.55q1.09-1.95,2.38-3.8c.22.26,4.49,5,4.49,5l1.53-5.86,1,.45-.37-2.81.64-2.47,3.58-4.52-2-2.65,2.31-1.62.85,1.55,1-2.85,3.3-2.3L50-6.7,36-8.27,20.4-5.5Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4392" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M45.91,22.37s1.51-2,1.48-2.05Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4393" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M49.54,16.13a4.78,4.78,0,0,0,1-.34Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4394" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeMiterlimit="10" d="M52.32,22.22" transform="translate(32.71 31.41)" />
                                    <path id="Path_4395" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M42.76,24l-.65,1.5s-1.49-1.81-1.52-2Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4396" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M42.36,32.3l8.71-4.43,2.35,6.68L51,41.07,47,36.63l-4.91.06Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4398" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M54.33,42.28l1.25-1.73Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4399" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M9.16,3.76l1-3.37Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4400" fill="none" stroke="#ffffff" strokeWidth="2.5" strokeLinejoin="round" d="M16.61-8.38,15.26-7Z" transform="translate(32.71 31.41)" />
                                    <path id="Path_4411" className="svg-hover-stroke" fill="none" strokeWidth="2.5" d="M-10.69,51.54a44.21,44.21,0,0,1,40.43-78.3" transform="translate(32.71 31.41)" />
                                    <path id="Path_4412" className="svg-hover-stroke" fill="none" strokeWidth="2.5" d="M-10.85,44.69l1.34,7.63-7.63,1.34" transform="translate(32.71 31.41)" />
                                </svg>
                                <span>STANDARD GEOGRAPHY</span>
                            </div>
                            <div className="exploreOptionBtn optionBtn optionBtnAlt" data-panel="explore" onClick={this.hideOpeningBtns.bind(this, 'explore')}>
                                <svg id="optExploreIcon" className="lnr" xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 90.78 87.07">
                                    <path id="Path_4389" d="M-25.48-11l.05-2,4-4,4.15.05L-5-18.94-5.07-17-7-14.93l3.85,4.07-8,8.23,0,3.87,8,2L1,7.29l-2,4.42L-3,13.34-5,23.51l-4.29-2-1.74-10-4.19-4.17,2-4-6-6.1-2.2-8.08Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4390" d="M-.08-19l5,0v3.15S0-12.79,0-12.9s-1.8-3-1.8-3Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4391" d="M8.85-10.9,6.61-5.25l8.28-3.62v2.18L6.61-.93,4.81,5.41l6,4.07,1.8,9.81,4.24,4.17,6.18-12L23,7.1,19,1.64,14.5-.69l4.34-.2L23,2.53l4-2.28,3,4,.72-2a34.27,34.27,0,0,1,2.23-3c.2.2,4.19,3.92,4.19,3.92l1.42-4.62.92.35L39.24-3.3l.6-1.95,3.35-3.57L41.34-10.9l2.15-1.27.79,1.22,1-2.25L48.32-15l-6-.8L29.26-17,14.69-14.87Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4392" d="M38.51,7.12s1.41-1.86,1.38-1.92Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4393" d="M41.9,2.06a3.74,3.74,0,0,0,1-.32Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4394" d="M44.5,6.9" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="2.5" />
                                    <path id="Path_4395" d="M35.57,8.14,35,9.54s-1.39-1.7-1.42-1.84Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4396" d="M35.2,13.38l8.13-4.14,2.2,6.24-2.25,6.08-3.79-4.14-4.59,0Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4398" d="M46.37,22.69l1.17-1.61Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4399" d="M4.19-7.43l.9-3.14Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4400" d="M11.15-17.15,9.9-15.9Z" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeLinejoin="round" strokeWidth="2.5" />
                                    <path id="Path_4109" d="M50.82,32.89l3.73.74V-28.08L31-32.24,8.27-23.35-10.9-30.51l-22.83,9.08V40.28l22.83-9.09L2.87,35.61" transform="translate(34.98 33.53)" fill="none" stroke="#ffffff" strokeWidth="2.5" />
                                    <path id="Path_4114" className="svg-hover-stroke" d="M29.5,35.3A2.53,2.53,0,1,0,27,32.78,2.52,2.52,0,0,0,29.5,35.3Z" transform="translate(34.98 33.53)" fill="none" strokeWidth="2.5" />
                                    <path id="Path_4115" className="svg-hover-stroke" d="M29.56,52.85s-9.67-14.63-9.67-19.94a9.65,9.65,0,0,1,9.67-9.63h0c5.06,0,10,4.5,9.68,9.63-.14,2.5-1.7,6-3.22,9C34.6,44.73,33.21,47,33.21,47" transform="translate(34.98 33.53)" fill="none" strokeWidth="2.5" />
                                </svg>
                                <span>EXPLORE/PRINT MAP</span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default OpeningBtns;