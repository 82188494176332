import React from 'react';
import NavBar from '../MainPage/NavBar/NavBar';
import SupportSub from './SupportSub/SupportSub';
import SupportMain from './SupportMain/SupportMain';

class Support extends React.Component {
  render() {
    return (
      <div>
        <NavBar
          subTitle={'Support Center'}
          link={'/'}
          linkTitle={'Restaurant Bytes'}
          isMain={false}
        ></NavBar>
        <SupportSub/>
        <SupportMain/>
      </div>
    )
  }
}

export default Support;