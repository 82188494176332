import React from 'react';
import './../App.css';
import MapComponent from './MapComponent/MapComponent';
import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import axios from 'axios';

const appDiv = {
  height: '100%',
}

const splashScreenStyle = {
  zIndex: 1000,
  backgroundColor: '#ffffff',
  opacity: 0.9,
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
}

const agreeDiv = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const fontSmall = {
  fontSize: '8pt',
  color: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

const apiToken = localStorage.getItem('access_token');

const headers = {
  'Authorization': 'Bearer ' + apiToken
};

const userName = localStorage.getItem('user_name');

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      points: [],
      polys: [],
      editControlLayer: {},
      mapCenter: [],
      mapZoom: 12,
      radiusVal: 1,
      splashScreen: true,
      mapAddPt: false,
      mapDrawPt: false,
      segmentFilterVal: [],
      subsegmentFilterVal: [],
      categoryFilterVal: [],
      chainFilterVal: [],
      minSalesFilterVal: 0,
      maxSalesFilterVal: 50,
      mapErrorMsg: false,
      mapRestPoint: false,
      driveTime: false,
      mapBounds: {},
      mapMoved: false,
      mapPrintCenter: []
    }

    this.updatePointsArray = this.updatePointsArray.bind(this);
    this.updatePolyArray = this.updatePolyArray.bind(this);
    this.updateRadiusVal = this.updateRadiusVal.bind(this);
    this.updateMapCenter = this.updateMapCenter.bind(this);
    this.clearSplashScreen = this.clearSplashScreen.bind(this);
    this.updateMapAddPt = this.updateMapAddPt.bind(this);
    this.updateMapDrawPt = this.updateMapDrawPt.bind(this);
    this.updateFilterVal = this.updateFilterVal.bind(this);
    this.updateMapZoom = this.updateMapZoom.bind(this);
    this.updateOpeningBtns = this.updateOpeningBtns.bind(this);
    this.updateMapRestPoint = this.updateMapRestPoint.bind(this);
    this.updateDriveTime = this.updateDriveTime.bind(this);
    this.updateMapBounds = this.updateMapBounds.bind(this);
    this.updateMapMoved = this.updateMapMoved.bind(this);
    this.updateMapPrintCenter = this.updateMapPrintCenter.bind(this);
    this.updateEditControlLayer = this.updateEditControlLayer.bind(this);
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_APIURL + "/getOfficeCoordsUserName?username=" + userName, { headers: headers })
      .then((result) => {
        if (result.data.x) {
          this.setState({
            mapCenter: [result.data.y, result.data.x],
            mapPrintCenter: [result.data.y, result.data.x]
          });
        } else {
          this.setState({
            mapCenter: [40, -112],
            mapPrintCenter: [10, -112]
          });
        }
      }).catch(error => {
        console.log(error);
        this.setState({
          mapCenter: [40, -112],
          mapPrintCenter: [40, -112]
        });
      });
  }

  updatePointsArray(pointsArray) {
    this.setState({
      points: pointsArray,
    });
  }

  updatePolyArray(polyArray) {
    this.setState({
      polys: polyArray
    });
  }

  updateRadiusVal(newRadius) {
    this.setState({
      radiusVal: newRadius
    });
  }

  updateMapCenter(position) {
    this.setState({
      mapCenter: [position.lat, position.lng]
    });
  }

  updateMapZoom(zoom) {
    this.setState({
      mapZoom: zoom
    });
  }

  updateMapAddPt(bool) {
    this.setState({
      mapAddPt: bool
    });
  }

  updateMapDrawPt(bool) {
    this.setState({
      mapDrawPt: bool
    });
  }

  updateMapBounds(bounds) {
    this.setState({
      mapBounds: bounds
    });
  }

  updateMapMoved(bool) {
    this.setState({
      mapMoved: bool
    });
  }

  updateMapPrintCenter(mapCenter) {
    this.setState({
      mapPrintCenter: [mapCenter.lat, mapCenter.lng]
    });
  }

  updateFilterVal(updateArray) {
    let updateObj = {};
    let segFilterClone = Array.from(this.state.segmentFilterVal);
    let subsegFilterClone = Array.from(this.state.subsegmentFilterVal);
    let catFilterClone = Array.from(this.state.categoryFilterVal);
    let chainFilterClone = Array.from(this.state.chainFilterVal);

    for (const item of updateArray) {
      if (item.type === 'minSales') {
        updateObj.minSalesFilterVal = item.value;
      } else if (item.type === 'maxSales') {
        updateObj.maxSalesFilterVal = item.value;
      } else if (item.type === 'reset') {
        updateObj.minSalesFilterVal = 0;
        updateObj.maxSalesFilterVal = 50;
        updateObj.segmentFilterVal = [];
        updateObj.subsegmentFilterVal = [];
        updateObj.categoryFilterVal = [];
        updateObj.chainFilterVal = [];
      } else {
        let filterVal = segFilterClone;
        let objPropName = 'segmentFilterVal';
        if (item.type === 'subsegment') {
          filterVal = subsegFilterClone;
          objPropName = 'subsegmentFilterVal';
        } else if (item.type === 'category') {
          filterVal = catFilterClone;
          objPropName = 'categoryFilterVal';
        } else if (item.type === 'chain') {
          filterVal = chainFilterClone;
          objPropName = 'chainFilterVal';
        }

        if (item.add) {
          filterVal.push(item.value);
        } else {
          if (item.value !== 'resetVal') {
            filterVal = filterVal.filter(e => e !== item.value);
             
            if (item.type === 'segment') {
              segFilterClone = filterVal;
            } else if (item.type === 'subsegment') {
              subsegFilterClone = filterVal;
            } else if (item.type === 'category') {
              catFilterClone = filterVal;
            } else if (item.type === 'chain') {
              chainFilterClone = filterVal;
            }
          }
        }

        updateObj[objPropName] = filterVal;
      }
    }
    
    this.setState(updateObj);
  }

  updateMapRestPoint(bool) {
    this.setState({
      mapRestPoint: bool
    });
  }

  updateOpeningBtns(bool) {
    this.setState({
      showOpeningBtns: bool
    });
  }

  updateDriveTime(bool) {
    this.setState({
      driveTime: bool
    });
  }

  updateEditControlLayer(obj) {
    this.setState({
      editControlLayer: obj
    });
  }

  clearSplashScreen() {
    if (document.getElementById('agreeChkBox').checked) {
      this.setState({
        splashScreen: false
      });
    }
  }

  render() {
    return (
      <div style={appDiv}>
        <div style={appDiv}>
          <NavBar
            subTitle={'Restaurant Trends Analysis'}
            link={'/support'}
            linkTitle={'Restaurant Bytes Support Center'}
            isMain={true}
          ></NavBar>
          <div className="pageContainer">
            <SideBar
              key={"key_" + this.mapRestPoint}
              updatePointsArray={this.updatePointsArray}
              updatePolyArray={this.updatePolyArray}
              updateMapCenter={this.updateMapCenter}
              updateMapZoom={this.updateMapZoom}
              updateRadiusVal={this.updateRadiusVal}
              radiusVal={this.state.radiusVal}
              minSalesFilterVal={this.state.minSalesFilterVal}
              maxSalesFilterVal={this.state.maxSalesFilterVal}
              points={this.state.points}
              polys={this.state.polys}
              segmentFilterVal={this.state.segmentFilterVal}
              subsegmentFilterVal={this.state.subsegmentFilterVal}
              categoryFilterVal={this.state.categoryFilterVal}
              chainFilterVal={this.state.chainFilterVal}
              updateMapAddPt={this.updateMapAddPt}
              updateMapDrawPt={this.updateMapDrawPt}
              updateFilterVal={this.updateFilterVal}
              mapRestPoint={this.state.mapRestPoint}
              updateMapRestPoint={this.updateMapRestPoint}
              driveTime={this.state.driveTime}
              updateDriveTime={this.updateDriveTime}
              mapBounds={this.state.mapBounds}
              mapMoved={this.state.mapMoved}
              updateMapMoved={this.updateMapMoved}
              mapPrintCenter={this.state.mapPrintCenter}
              updateEditControlLayer={this.updateEditControlLayer}
              mapCenter={this.state.mapCenter}
            ></SideBar>
            {this.state.mapCenter.length > 0 ?
              <MapComponent
                updatePointsArray={this.updatePointsArray}
                updatePolyArray={this.updatePolyArray}
                updateMapCenter={this.updateMapCenter}
                updateMapZoom={this.updateMapZoom}
                points={this.state.points}
                polys={this.state.polys}
                mapCenter={this.state.mapCenter}
                mapZoom={this.state.mapZoom}
                radiusVal={this.state.radiusVal}
                mapAddPt={this.state.mapAddPt}
                mapDrawPt={this.state.mapDrawPt}
                segmentFilterVal={this.state.segmentFilterVal}
                subsegmentFilterVal={this.state.subsegmentFilterVal}
                categoryFilterVal={this.state.categoryFilterVal}
                chainFilterVal={this.state.chainFilterVal}
                minSalesFilterVal={this.state.minSalesFilterVal}
                maxSalesFilterVal={this.state.maxSalesFilterVal}
                updateMapRestPoint={this.updateMapRestPoint}
                driveTime={this.state.driveTime}
                updateMapBounds={this.updateMapBounds}
                mapMoved={this.state.mapMoved}
                updateMapMoved={this.updateMapMoved}
                updateMapPrintCenter={this.updateMapPrintCenter}
                editControlLayer={this.state.editControlLayer}
                updateEditControlLayer={this.updateEditControlLayer}
              ></MapComponent>
              : 
              null
              }
          </div>
        </div>
        {this.state.splashScreen ?
          <div>
            <div style={splashScreenStyle}>

            </div>
            <div className="agreementText">
              Restaurant Trends data files are the property of Restaurant Trends, are solely being licensed to Colliers,
              &nbsp;<u><b>are extremely confidential data</b></u>, and are not the property of Colliers or any Colliers offices.
              <br />
              <br />
              Colliers is permitted to show data files to clients and prospects but&nbsp;ONLY&nbsp;to the extent that:
              <ul>
                <li>
                  <b>a)</b> Each client is informed that all RESTAURANT TRENDS data files are the property of RESTAURANT TRENDS, are solely being licensed to COLLIERS, are extremely confidential data, and are not the property of COLLIERS or any of the COLLIERS' offices.
                </li>
                <li>
                  <b>b)</b> Each client will only be shown RESTAURANT TRENDS data which is in the client's current or potential geographic operating trade areas, with the number of data file records (meaning one line on the data file) not to exceed 2,500 data file records per client per year. For an amount larger than 2,500 records per year, COLLIERS must contact RESTAURANT TRENDS for written &nbsp;consent.
                </li>
              </ul>
              Please note: Restaurant Bytes reports are limited to <b>200</b> records.
              <div style={agreeDiv}>
                <div style={fontSmall}>
                  <input type="checkbox" id="agreeChkBox" name="agreeChkBox" />
                  <div>I agree to the above terms and conditons</div>
                </div>
                <div>
                  <button onClick={this.clearSplashScreen} className="geocodeBtn">I Agree</button>
                </div>
              </div>
            </div>
          </div>
          :
          null}
      </div>
    );
  }
}

export default MainPage;
