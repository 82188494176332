import React, { Component } from 'react';

const subHeaderStyle = {
    padding: '20px 40px 10px 40px',
    color: '#6e6e71',
    fontSize: '14px',
    textAlign: 'center'
}

export default class SupportSub extends Component {
  render() {
    return (
      <div style={subHeaderStyle}>
           Restaurant Bytes is built upon data from Restaurant Trends.  Restaurant Trends gives competitive intelligence on 182,000+ QSR/FSR restaurant locations nationally, with restaurant market sales and volume data for 476 chains.  Please note that the information from Restaurant Trends is confidential and that there is strict governance on the data and how it is shared.
      </div>
    );
  }
}